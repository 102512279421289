import { Carousel, Slide } from "vue-carousel";
import Component, { mixins } from "vue-class-component";
import BrandtVue from "../common/brandt-vue";
import TutorialCarouselMixin from "../common/tutorial-carousel.mixin";
import "./home.scss";

if (document.querySelector(".HomePage")) {

  @Component({
    components: {
      carousel: Carousel,
      slide: Slide
    }
  })
  class HomePage extends mixins(BrandtVue, TutorialCarouselMixin) {

  }
  const homePage = new HomePage({ el: ".HomePage" });

}
