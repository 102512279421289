import Component from "vue-class-component";
import BrandtVue from "../common/brandt-vue";
import "./error.scss";

if (document.querySelector(".ErrorPage")) {

  @Component({})
  class ErrorPage extends BrandtVue {

  }

  const errorPage = new ErrorPage({ el: ".ErrorPage" });

}
