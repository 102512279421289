




































import Vue from "vue";
import Component from "vue-class-component";
import { ROUTES } from "./account.page";
import Account from "./account";
import authenticationService from "../common/authentication/authentication.service";

@Component({
  props: ["account"]
})
export default class HomeComponent extends Vue {

  public ROUTES = ROUTES;
  public account: Account;

  public async logout() {
    await authenticationService.logout();
    window.location.href = "/";
  }

  public userOrFullName() {
    if (this.account.firstName && this.account.lastName) {
      return this.account.firstName + " " + this.account.lastName;
    }
    return this.account.email;
  }

}
