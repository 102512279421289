

































































import Vue from "vue";
import Component from "vue-class-component";
import Alert from "../ui/alert";
import AlertComponent from "../ui/alert.component.vue";
import AUTHENTICATION_CONSTANTS from "./authentication.constants";
import ForgotPasswordComponent from "./forgot-password.component.vue";
import LoginFormComponent from "./login-form.component.vue";
import RegisterComponent from "./register.component.vue";

@Component({
  template: "#common-authentication-login-modal-template",
  components: {
    "login-form": LoginFormComponent,
    "register": RegisterComponent,
    "forgot-password": ForgotPasswordComponent,
    "alert": AlertComponent,
  },
})
export default class LoginModalComponent extends Vue {

  public readonly ACTION = AUTHENTICATION_CONSTANTS.ACTION;
  public action = AUTHENTICATION_CONSTANTS.ACTION.LOGIN;
  public alert: Alert = null;

  public stopPropagation($event: Event) {
    $event.stopPropagation();
  }

  public onLogin() {
    this.$emit("login");
    this.$emit("close");
  }

}
