import Component from "vue-class-component";
import BrandtVue from "../common/brandt-vue";

if (document.querySelector(".WebsitePagePage")) {

  @Component({})
  class WebsitePagePage extends BrandtVue {
  }

  const websitePagePage = new WebsitePagePage({ el: ".WebsitePagePage" });

}
