import { render, staticRenderFns } from "./gender-selector.component.vue?vue&type=template&id=c2b62736&"
import script from "./gender-selector.component.vue?vue&type=script&lang=ts&"
export * from "./gender-selector.component.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "gender-selector.component.vue"
export default component.exports