import _ from "lodash";
import "../../../static-src/js/dqe/jquery.dqe";
import "../../../static-src/js/dqe/jquery.dqemail";
import Address from "../../account/address";

declare var window: any;
declare var $: JQueryStatic;
declare var apiUrl: string;

let nextUniqueId = 0;

export enum DqeStatus {
    Success = "success",
    Error = "error",
    Warning = "warning"
}

export interface DqeRnvpResult {
    status: DqeStatus;
    message: string;
    allowedStreetNumbers?: number;
    corrections?: Address;
}

export class DqeService {
    public static RSVPKeyBindings = {
        street: "line1",
        zip: "zipCode",
        city: "city",
        country: "countryCode"
    };
    private dqeObject: any;

    constructor() {
        this.setupDqe();
    }

    public async checkEmail(email: string) {
        return new Promise((resolve, reject) => {
            const options = this.getOptions();
            options.autocheck = false;
            options.bootstrap = 0;
            const input = $("<input/>") as any;
            input.val(email);
            const container = $(
                "<div></div>"
            ) as any;
            container.append(input);
            const dqeEmailObject = input.dqemail(options);
            dqeEmailObject.on("checked", function(ui: any, data: any) {
                resolve({
                    status:
                        (data.code === "00" || data.code === "01")
                            ? DqeStatus.Success
                            : DqeStatus.Error,
                    message: data.msg
                });
                dqeEmailObject.off("checked");
            });
            dqeEmailObject.check();
        });
    }

    public async checkAddress(address: Address): Promise<any> {
        const self = this;
        return new Promise((resolve, reject) => {
            const callbackName = `idCheckCallback${nextUniqueId}`;
            window[callbackName] = function(data: any) {
                var dqeResponse = Array.isArray(data) && data.length > 0 ? data[0] : data;
                if (dqeResponse.error) {
                    resolve({
                        message: dqeResponse.label,
                        status: DqeStatus.Error,
                        allowedStreetNumbers: !!dqeResponse.known_numbers
                            ? dqeResponse.known_numbers.split(";")
                            : null
                    } as DqeRnvpResult);
                } else {
                    const corrections = self.fromRSVPAddress(dqeResponse.corrections);
                    const result: DqeRnvpResult = {
                        message: dqeResponse.label,
                        corrections: corrections,
                        allowedStreetNumbers: dqeResponse.known_numbers || null,
                        status: corrections
                            ? DqeStatus.Warning
                            : DqeStatus.Success
                    };
                    resolve(result);
                }
                delete window[callbackName];
            };
            // street, zip, city, lastname, firstname, callback_function_name
            this.dqeObject.check(callbackName, {
                street: address.line1,
                zip: address.zipCode,
                city: address.city,
                country: this.dqeObject.convert_iso2(address.countryCode)
            });
        });
    }

    private fromRSVPAddress(address: any) {
        if (!address) {
            return null;
        }
        const bindedCorrections = _.reduce(
            DqeService.RSVPKeyBindings,
            (result, value, key) => {
                if (!!address[key]) {
                    result[value] = address[key];
                }
                return result;
            },
            {} as any
        );
        return _.isEmpty(bindedCorrections) ? null : bindedCorrections
    }

    private getOptions(): any {
        return {
            server: apiUrl + "/api/1.0.0/data-quality",
            license: "",
            taille: 30
        };
    }

    private setupDqe() {
        const options = this.getOptions();

        this.dqeObject = ($("<div></div>") as any).dqe(options);
    }
}

export default new DqeService();
