import apiClient from "../services/api.client";
import moment from "moment-timezone";
import { Review } from "./review";

export class NetReviewsService {
  getProductReviews(productReference: string): Promise<Review[]> {
    return apiClient.get(`/product-reviews/${productReference}?page=0&size=10`);
  }

  async getSiteReviews(): Promise<Review[]> {
    const response = await apiClient.get<Review[]>(`/site-reviews?page=0&size=4&locale=${locale}`)

    return response.map(rawReview => {
      const review = {...rawReview};
      review.date = moment(rawReview.date).toDate();

      return review;
    });
  }
}

export default new NetReviewsService();