




















































































































import Vue from "vue";
import Component from "vue-class-component";
import Product from "./product";
import ModalComponent from "../common/ui/modal.component.vue";
import AlertProductService from "./alert-product.service";
import AlertProduct from "./alert-product";
import alertProductService from "./alert-product.service";
import { setTimeout } from "timers";

@Component({
  name: "alert-product-modal",
  props: {
    product: Object
  },
  components: {
    modal: ModalComponent
  }
})
export default class AlertProductModal extends Vue {
  public email: string = null;
  public isSent: boolean = false;

  constructor() {
    super();
  }

  public receiveAlertWhenAvailable() {
    const toSend: AlertProduct = {
      productId: this.$props.product.id,
      userEmail: this.email,
      brand: "brandt",
      locale: "fr_FR"
    };
    alertProductService.addAlertOnProduct(toSend).then(response => {
        this.isSent = true;
        setTimeout(()=>{this.$emit("close");},3000);
  
    })
  }
}
