











































































































import Component, {mixins} from "vue-class-component";
import {validationMixin} from "vuelidate";
import {email, minLength, required} from "vuelidate/lib/validators";
import FieldComponent from "../common/ui/field.component.vue";
import authenticationService from "../common/authentication/authentication.service";
import AlertComponent from "../common/ui/alert.component.vue";
import VueRouter from "vue-router";
import SpinnerComponent from "../common/ui/spinner.component.vue";
import reCaptchaService from "../common/services/re-captcha.service";

declare var locale: string;
declare var redirectTo: string;
declare var dataLayer: any;
declare var privacyPolicyLink: string;

@Component({
  components: {
    alert: AlertComponent,
    field: FieldComponent,
    spinner: SpinnerComponent,
  },
  validations: {
    credentials: {
      lastName: {
        required,
        minLength: minLength(2)
      },
      firstName: {
        required,
        minLength: minLength(2)
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      }
    },
    passwordRepeat: {
      required,
      passwordRepeat: function(value: string) {
        return this.credentials.password === value;
      }
    }
  }
} as any)
export default class OrderPersonalDataRegisterComponent extends mixins(
  validationMixin
) {
  public privacyPolicyLink = privacyPolicyLink;
  public passwordRepeat: string = null;
  public $t: any;
  public credentials: any = {
      locale
  };
  public $router: VueRouter;
  public isLoading = false;
  public errorMessageKey: string = null;
  public marketingOptin: boolean = false;

  getErrors(fieldId: string) {}

  public async register() {
    this.isLoading = true;

    this.credentials.reCaptchaResponse = await reCaptchaService.getSiteToken("orderRegistration");

    try {
      this.credentials['marketingOptin'] = this.marketingOptin;
      await authenticationService.register(this.credentials);

      dataLayer.push({
        'event': 'interactionDone',
        'categoryGA': 'account',
        'actionGA': 'creationOK',
        'labelGA': 'insideCheckout'
      });

      await authenticationService.login(this.credentials);

      window.location.href = redirectTo;
    } catch (error) {
      if (error.response && error.response.status) {
        switch (error.response.status) {
          case 400:
            this.errorMessageKey = "register.user-exists";
            break;
        }
      }
      if (!this.errorMessageKey) {
        this.errorMessageKey = "common.error-code.server";
      }
      dataLayer.push({
        'event': 'interactionDone',
        'categoryGA': 'account',
        'actionGA': 'creationFail',
        'labelGA': 'insideCheckout'
      });
    }
    this.isLoading = false;
  }

  getErrorMessage(validation: any) {
    if (validation.email === false) {
      return this.$t("common.error.field-email");
    } else if (validation.minLength === false) {
      return this.$t("common.error.field-min-length", [6]);
    } else if (validation.passwordRepeat === false) {
      return this.$t("common.error.field-password-repeat");
    }
    return this.$t("common.error.field-required");
  }
}
