



























































































import Component, {mixins} from "vue-class-component";
import AlertComponent from "./ui/alert.component.vue";
import ModalComponent from "./ui/modal.component.vue";
import {AlertType} from "./ui/alert-type";
import FieldComponent from "./ui/field.component.vue";
import {required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import axios from "axios";

declare let grecaptcha: any;
declare let reCaptchaSiteKey: string;
declare var privacyPolicyLink: string;

@Component({
    components: {
        field: FieldComponent,
        alert: AlertComponent,
        modal: ModalComponent
    },
    validations: {
        phone: {
            required
        }
    }
} as any)
export default class BeCalledBackComponent extends mixins(validationMixin) {

    privacyPolicyLink: string = privacyPolicyLink;
    AlertType = AlertType;

    phone: string = "";

    $v: any;

    isError = false;
    isSuccess = false;

    openLink($event: any) {
        window.open($event.target.href, "_blank");
    }

    async submitForm() {
        if (this.$v.invalid) {
            return;
        }

        const reCaptchaResponse = await grecaptcha
            .execute(reCaptchaSiteKey, {
                action: "interventionForm",
            });

        const contactRequest = {
            phone: this.phone,
            brand,
            reCaptchaResponse: reCaptchaResponse
        };

        try {
            await axios.post(`${apiUrl}/api/1.0.0/support-requests`, contactRequest);
            this.isSuccess = true;
            this.isError = false;
        } catch (err) {
            this.isSuccess = false;
            this.isError = true;
        }
    }
}
