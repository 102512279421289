import Component, { mixins } from "vue-class-component";
import BrandtVue from "../common/brandt-vue";
import PartFinderMixin from "../end-product/part-finder.mixin";
import FindReferenceTip from "./find-reference-tip";

declare var findReferenceTips: FindReferenceTip[];
declare var servicesLink: string;

if (document.querySelector(".FindReferencePage")) {

  @Component({})
  class FindReferencePage extends mixins(BrandtVue, PartFinderMixin) {

    public servicesLink = servicesLink;
    public findReferenceTips = findReferenceTips;
    public selectedIndex = 0;
    public selectedTip: FindReferenceTip;
    public tipsDropdownVisible = true;
    public isMobile = false;

    public created() {
      this.selectedTip = this.findReferenceTips[this.selectedIndex];
      window.addEventListener("resize", () => {
        this.tipsDropdownVisible = document.documentElement.clientWidth > 960;
      });
    }

    public selectTip(index: number) {
      this.selectedIndex = index;
      this.selectedTip = this.findReferenceTips[index];
      this.tipsDropdownVisible = document.documentElement.clientWidth > 960;
    }

  }

  const findReferencePage = new FindReferencePage({ el: ".FindReferencePage" });

}
