
























































import Vue from "vue";
import Component from "vue-class-component";
import Order from "./order.model";
import OrderReturnForm from "./order-return-form.component.vue";

@Component({
  props: {
    order: Object
  },
  components: {
    'order-return-form': OrderReturnForm
  }
})
export default class OrderReturnFormModal extends Vue {
  
  public order: Order;

}
