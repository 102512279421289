








































































import Component, { mixins } from "vue-class-component";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import FieldComponent from "../common/ui/field.component.vue";
import GenderSelector from "./gender-selector.component.vue";
import BrithDateSelectorComponent from "../common/ui/birthdate-selector.component.vue";
import accountService from "./account.service";
import Account from "./account";
import AlertComponent from "../common/ui/alert.component.vue";

@Component({
  components: {
    alert: AlertComponent,
    field: FieldComponent,
    "gender-selector": GenderSelector,
    "birthdate-selector": BrithDateSelectorComponent
  },
  props: {
    account: Object,
    context: Object
  },
  validations: {
    account: {
      gender: {
        required
      },
      firstName: {
        required
      },
      lastName: {
        required
      },
      birthDate: {
        required
      },
      email: {
        required
      },
      newsletterSubscriber: {

      }
    }
  }
} as any)
export default class PersonalDataComponent extends mixins(validationMixin) {
  public ErrorCode = {
    Server: "server"
  };
  public account: Account;
  public submitting = false;
  public submitSuccess = false;
  public submitErrorCode: string = null;
  public context: any;

  public async updatePersonalData() {
    this.submitting = true;
    this.context.isLoading = true;
    try {
      await accountService.updateAccount(this.account);
      this.submitSuccess = true;
    } catch(error) {
      this.submitErrorCode = this.ErrorCode.Server;
    }
    this.submitting = false;
    this.context.isLoading = false;
  }
}
