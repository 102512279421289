import Component from "vue-class-component";
import BrandtVue from "../common/brandt-vue";
import SliderComponent from "../common/ui/slider.component.vue";

if (document.querySelector("#ProductPage")) {

    @Component({
        components: { SliderComponent },
    })
    class ProductPage extends BrandtVue {
    }

    const productPage = new ProductPage({ el: "#ProductPage" });

}
