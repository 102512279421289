import Vue from "vue";
import Component from "vue-class-component";
import VueRouter, { Route, RouteConfig } from "vue-router";
import authenticationService from "../common/authentication/authentication.service";
import BrandtVue from "../common/brandt-vue";
import i18nService from "../common/i18n.service";
import OrderPersonalDataForgottenPasswordComponent from "./order-personal-data-forgotten-password.component.vue";
import OrderPersonalDataComponent from "./order-personal-data.component.vue";

const i18n = i18nService.getI18n();

const PATHS = {
  FORGOTTEN_PASSWORD: i18n.t("order.paths.forgottenPassword") as string,
  LOGIN: ""
};

export const ROUTES = {
  FORGOTTEN_PASSWORD: `/${PATHS.FORGOTTEN_PASSWORD}`,
  LOGIN: `/${PATHS.LOGIN}`
};

declare var redirectTo: string;

if (document.querySelector(".LoginPage")) {
  Vue.use(VueRouter);

  const routes: RouteConfig[] = [
    {
      path: ROUTES.FORGOTTEN_PASSWORD,
      component: OrderPersonalDataForgottenPasswordComponent,
      beforeEnter: (to: Route, from: Route, next: any) => {
        if (authenticationService.isAuthenticated()) {
          window.location.href = redirectTo;
          return;
        } else {
          next();
        }
      }
    },
    {
      path: ROUTES.LOGIN,
      component: OrderPersonalDataComponent,
      beforeEnter: (to: Route, from: Route, next: any) => {
        if (authenticationService.isAuthenticated()) {
          window.location.href = redirectTo;
          return;
        } else {
          next();
        }
      }
    }
  ];

  const router = new VueRouter({
    routes,
  });

  @Component({
    router
  })
  class LoginPage extends BrandtVue {

  }

  const loginPage = new LoginPage({ el: ".LoginPage" });

}
