






















































































































































import Component, {mixins} from "vue-class-component";
import {validationMixin} from "vuelidate";
import FieldComponent from "../../common/ui/field.component.vue";
import Address from "../../account/address";
import _ from "lodash";
import i18nService from "../../common/i18n.service";
import orderService from "../order.service";
import VueRouter, {Route} from "vue-router";
import OrderPreferenceAddressComponent from "./order-preference-address.component.vue";
import orderStore from "./order.store";
import {ROUTES} from "./order.page";
import Order from "../../account/order.model";

const i18n = i18nService.getI18n();

declare var termsAndConditionsLink: string;
declare var termsAndConditionsDiagnosticLink: string;
declare var privacyPolicyLink: string;
declare var dataLayer: any;

@Component({
  components: {
    field: FieldComponent,
    "order-preference-address": OrderPreferenceAddressComponent
  },
  watch: {
    shippingAddressInvalid() {
      this.updateIsInvalid();
    },
    billingAddressInvalid() {
      this.updateIsInvalid();
    },
    termsAndConditionsAccepted: function(value: boolean) {
      orderStore.setTermAndConditionsAccepted(value);
      this.updateIsInvalid();
    },
    "state.shippingAndBillingAddressDifferent": function() {
      this.updateIsInvalid();
    }
  }
})
export default class OrderPreferenceComponent extends mixins(validationMixin) {
  public privacyPolicyLink = privacyPolicyLink;
  public $router: VueRouter;
  public $route: Route;
  public state = orderStore.state;
  public shippingAddress: Address = null;
  public invoicingAddress: Address = null;
  public instructions: string = null;
  public context: any;
  public tacAccepted = false;
  public shippingAddressInvalid = false;
  public billingAddressInvalid = true;
  public termsAndConditionsAccepted = false;
  public termsAndConditionsLink = termsAndConditionsLink;
  public termsAndConditionsDiagnosticLink = termsAndConditionsDiagnosticLink;
  public formInvalid = true;

  public beforeRouteEnter(to: Route, from: Route, next: any) {
    next((vm: OrderPreferenceComponent) => {
      orderStore.setTermAndConditionsAccepted(false);
    });
  }

  public created() {
    let self = this;

    (async () => {
      if (this.$route.query.orderId) {
        const orderId = this.$route.query.orderId as string;
        const order = await orderService.getOrder(orderId);
        self.shippingAddress = order.shippingAddress;
        self.invoicingAddress = order.invoicingAddress;
        self.invoicingAddress.phoneNumber = order.phoneNumber;
        self.instructions = order.instructions;
      } else {
        await orderStore.getAddresses();
        self.shippingAddress = _.cloneDeep(
          orderStore.state.order.shippingAddress
        );
        self.invoicingAddress = _.cloneDeep(
          orderStore.state.order.invoicingAddress
        );
        self.instructions = orderStore.state.order.instructions;
      }
    })();

    this.setAccountInfos();
  }

  private async setAccountInfos(){
    await orderStore.setAccountInfos();
    this.state = orderStore.state;
    this.invoicingAddress = this.state.order.invoicingAddress;
  }

  public updateIsInvalid() {
    this.formInvalid = (this.$refs.invoicing as any).checkForm();
    orderStore.setPreferencesFormInvalid(
      this.billingAddressInvalid ||
        (this.shippingAddressInvalid &&
          this.state.shippingAndBillingAddressDifferent) ||
        !this.termsAndConditionsAccepted
    );
  }

  public setBillingAddressInvalid(value: boolean) {
    this.billingAddressInvalid = value;
    this.updateIsInvalid();
  }

  public setShippingAddressInvalid(value: boolean) {
    this.shippingAddressInvalid = value;
    this.updateIsInvalid();
  }

  public setShippingAndBillingAddressDifferent(value: boolean) {
    orderStore.setShippingAndBillingAddressDifferent(value);
  }

  public async createOrder() {
    await orderStore.createOrder(
      this.invoicingAddress,
      this.state.shippingAndBillingAddressDifferent
        ? this.shippingAddress
        : null,
      this.instructions
    );

    const order = orderStore.state.order as unknown as Order;

      dataLayer.push({
          event: "add_shipping_info",
          ecommerce: {
            currency: "EUR",
              value: order.totalAmount,
              shipping_tier: "Livraison",
            items: order.lines.map((line) => ({
                item_id: line.product.reference,
                item_name: line.product.title,
                price: line.product.price.value,
                quantity: line.quantity
            }))
          }
      });

    this.$router.push({ path: ROUTES.PAYMENT, query: { orderId: order.id } });
  }

  public conditionAccepted(event: boolean){
    this.termsAndConditionsAccepted = event;
    this.updateIsInvalid();
  }

  public markingOptinAccepted(event: boolean){
    orderStore.setMarketingOptin(event);
  }
}
