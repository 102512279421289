import Vue from "vue";
import Component from "vue-class-component";
import VueRouter, {RouteConfig} from "vue-router";
import authenticationService from "../common/authentication/authentication.service";
import BrandtVue from "../common/brandt-vue";
import i18nService from "../common/i18n.service";
import AddressListComponent from "./address-list.component.vue";
import AddressesComponent from "./addresses.component.vue";
import EditAddressComponent from "./edit-address.component.vue";
import HomeComponent from "./home.component.vue";
import OrderDetailsComponent from "./order-details.component.vue";
import OrderListComponent from "./order-list.component.vue";
import OrdersComponent from "./orders.component.vue";
import ProfileComponent from "./profile.component.vue";
import GdprComponent from "./gdpr.component.vue";
import AssistanceListComponent from "./assistance/assistance-list.component.vue";
import AssistancesComponent from "./assistance/assistances.component.vue";
import AssistanceCrossSellingComponent from "./assistance/assistance-cross-selling.component.vue"

import "./account.page.scss";
import accountService from "./account.service";

const i18n = i18nService.getI18n();

const PATHS = {
  HOME: i18n.t("account.paths.home") as string,
  ORDERS: i18n.t("account.paths.orders") as string,
  ORDER_LIST: "",
  ORDER_DETAILS: i18n.t("account.paths.order-details") as string,
  ADDRESSES: i18n.t("account.paths.addresses") as string,
  ADDRESS_LIST: "",
  EDIT_ADDRESS: i18n.t("account.paths.edit-address") as string,
  PROFILE: i18n.t("account.paths.profile") as string,
  GDPR: i18n.t("account.paths.gdpr") as string,
  ASSISTANCES: i18n.t("account.paths.assistances") as string,
  ASSISTANCE_LIST: "",
  ASSISTANCE_DETAILS: i18n.t("account.paths.assistance-details") as string,
  ASSISTANCE_CROSS_SELLING: i18n.t("account.paths.assistance-cross-selling") as string,
};

export const ROUTES = {
  HOME: `/${PATHS.HOME}`,
  ORDERS: `/${PATHS.ORDERS}`,
  ORDER_LIST: `/${PATHS.ORDERS}`,
  ORDERS_DETAILS: `/${PATHS.ORDERS}/${PATHS.ORDER_DETAILS}`,
  ASSISTANCES: `/${PATHS.ASSISTANCES}`,
  ASSISTANCE_LIST: `/${PATHS.ASSISTANCES}`,
  ASSISTANCE_DETAILS: `/${PATHS.ASSISTANCES}/${PATHS.ASSISTANCE_DETAILS}`,
  ASSISTANCE_CROSS_SELLING: `/${PATHS.ASSISTANCES}/${PATHS.ASSISTANCE_CROSS_SELLING}`,
  ADDRESSES: `/${PATHS.ADDRESSES}`,
  ADDRESSES_ADDRESS_LIST: `/${PATHS.ADDRESSES}/${PATHS.ADDRESS_LIST}`,
  ADDRESSES_EDIT_ADDRESS: `/${PATHS.ADDRESSES}/${PATHS.EDIT_ADDRESS}`,
  PROFILE: `/${PATHS.PROFILE}`,
  GDPR: `/${PATHS.GDPR}`
};

if (document.querySelector(".AccountPage")) {

  Vue.use(VueRouter);

  const routes: RouteConfig[] = [
    { path: ROUTES.HOME, component: HomeComponent },
    {
      path: ROUTES.ORDERS, component: OrdersComponent,
      children: [
        { path: PATHS.ORDER_LIST , component: OrderListComponent },
        { path: PATHS.ORDER_DETAILS + "/:id", component: OrderDetailsComponent }
      ],
    },
    {
      path: ROUTES.ASSISTANCES, component: AssistancesComponent,
      children: [
        { path: PATHS.ASSISTANCE_LIST , component: AssistanceListComponent },
        { path: PATHS.ASSISTANCE_DETAILS + "/:id", component: AssistanceListComponent },
        { path: PATHS.ASSISTANCE_CROSS_SELLING + "/:id", component: AssistanceCrossSellingComponent }
      ],
    },
    {
      path: ROUTES.ADDRESSES, component: AddressesComponent,
      children: [
        { path: PATHS.ADDRESS_LIST, component: AddressListComponent },
        { path: PATHS.EDIT_ADDRESS, component: EditAddressComponent },
        { path: PATHS.EDIT_ADDRESS + "/:id", component: EditAddressComponent },
      ],
    },
    { path: ROUTES.PROFILE, component: ProfileComponent },
    { path: ROUTES.GDPR, component: GdprComponent },
    { path: "*", redirect: ROUTES.HOME },
  ];

  const router = new VueRouter({
    routes,
  });

  @Component({
    router,
  })
  class AccountPage extends BrandtVue {

    public ErrorCode = {
      Server: "server",
    };
    public menuRoutes = {
      home: ROUTES.HOME,
      orders: ROUTES.ORDERS,
      assistances: ROUTES.ASSISTANCES,
      addresses: ROUTES.ADDRESSES_ADDRESS_LIST,
      profile: ROUTES.PROFILE,
      gdpr: ROUTES.GDPR
    };

    public links: { string: string };
    public account: any = {
      newsletter: false,
      gender: null,
      addresses: []
    };
    public isAddressUpdated = false;
    public nameToDefine = false;
    public context = {
      isLoading: false,
    };
    public errorCode: string = null;

    public created() {
      this.context.isLoading = true;
      (async () => {
        if (authenticationService.isAuthenticated()) {
          try {
            this.account = await accountService.getAccount();
          } catch (error) {
            this.errorCode = this.ErrorCode.Server;
          }
          this.context.isLoading = false;
        } else {
          window.location.href = "/";
        }
      })();
    }

    public logout() {
      authenticationService.logout();
      window.location.href = "/";
    }

    public getFullName() {
      let res = "";
      if (!!this.account.firstName && this.account.firstName !== "") {
        res += this.account.firstName + " ";
        this.nameToDefine = false;
      }
      if (!!this.account.lastName && this.account.lastName !== "") {
        res += this.account.lastName;
        this.nameToDefine = false;
      }
      this.$forceUpdate();
      return res;
    }
  }

  const accountPage = new AccountPage({ el: ".AccountPage" });

}
