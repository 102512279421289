import VueRouter from "vue-router";
import apiClient from "../common/services/api.client";
import authenticationService from "../common/authentication/authentication.service";
import {AccountAssistanceRdv} from "../account/assistance/assistance-rdv.model";
import {AssistanceType} from "./step/assistance-type.enum";
import {InterventionMotive} from "./step/intervention-motive.enum";

declare var locale: string;

export class AssistanceRDVService {
    public $router: VueRouter;

    public async updateAssistanceRDV(assistanceRDV: any): Promise<any> {
        return await apiClient.put<any, any>("/assistance/rdv", assistanceRDV);
    }

    public async getAssistanceTimeSlots(timeSlotsRequest: any): Promise<any> {
        var url = "/assistance";
        if (authenticationService.isAuthenticated()) {
            url += "/me";
        }
        url += "/get-time-slots";
        return await apiClient.put<any, any>(url, {
            type: timeSlotsRequest.type,
            cartId: timeSlotsRequest.cartId,
            locale
        });
    }

    public getTypeLabelKey(assistance: AccountAssistanceRdv): string {
        let assistanceType = assistance.assistanceRdv.assistanceType;
        let interventionMotive = assistance.assistanceRdv.interventionMotive;
        if (assistanceType === AssistanceType.DIAGNOSTIC) {
            return 'account.assistances.assistance-diag-type';
        } else if (assistanceType === AssistanceType.TECHNICAL) {
            return 'account.assistances.assistance-assist-type';
        }
        if (interventionMotive === InterventionMotive.COMMISSIONING) {
            return 'account.assistances.intervention-commissioning-type';
        } else if (interventionMotive === InterventionMotive.HOME_INTERVENTION) {
            return 'account.assistances.intervention-home-intervention-type';
        } else if (interventionMotive === InterventionMotive.STA) {
            return 'account.assistances.intervention-sta-type';
        }
        return 'account.assistances.default-type';
    }
}

export default new AssistanceRDVService();
