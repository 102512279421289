import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class BackToTopMixin extends Vue {

  public backToTopVisible = false;

  public mounted() {
    const scrollTrigger = 100; // px

    const showBackToTop = () => {
        const scrollTop = window.scrollY;
        this.backToTopVisible = scrollTop > scrollTrigger;
    };
    showBackToTop();
    window.addEventListener("scroll", () => {
      showBackToTop();
    });
  }

  public backToTop() {
    window.scrollTo(0, 0);
  }

}
