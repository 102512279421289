





























































































































































































































import _ from "lodash";
import Component, {mixins} from "vue-class-component";
import {validationMixin} from "vuelidate";
import FieldComponent from "../../common/ui/field.component.vue";
import AlertComponent from "../../common/ui/alert.component.vue";

declare var paypal: any;
declare var apiUrl: string;
declare var locale: string;
declare var paypalReturnLink: string;
declare var paypalClientId: string;
declare var abtastyEnabled: boolean;
declare var dataLayer: any;

@Component({
    props: {
        order: Object,
        paymentMethods: Array
    },
    components: {
        field: FieldComponent,
        alert: AlertComponent
    }
} as any)
export default class OrderPaymentChoiceComponent extends mixins(
    validationMixin
) {
    public paymentMethods: any[];
    public order: any;
    public $loadScript: any;

    private getPaymentMethod(type: string) {
        return _.find(
            this.paymentMethods,
            paymentMethod => paymentMethod.type === type
        );
    }

    public mounted() {
        this.$nextTick(() => {
            const paypalPaymentMethod = this.getPaymentMethod("PAYPAL_V2");
            if (!!paypalPaymentMethod) {
                this.initPaypal(paypalPaymentMethod.paypalId);
            } else {

            }
        });
    }

    public payOrder() {
        this.$emit("payorder");
    }

    public async initPaypal(paypalId: string) {
        // Lazy-load the paypal SDK
        await this.$loadScript(`https://www.paypal.com/sdk/js?client-id=${paypalClientId}&currency=EUR&disable-funding=card`);

        // Configure & render paypal button
        paypal.Buttons({
            // Call your server to set up the transaction
            createOrder: () => paypalId,

            // Call your server to finalize the transaction
            onApprove: () => {
                window.location.href = `${paypalReturnLink}?paymentId=${paypalId}`;
            }
        }).render('#paypal-button');
    }

    public handleSogenactifSubmit() {
        this.sendAddPaymentInfoEvent("Sogenactif");
    }

    private sendAddPaymentInfoEvent(paymentType: string) {
        const dataLayerProducts = _.map(this.order.lines, (item) => {
            return {quantity: item.quantity, ...item.product.dataLayer}
        });
        dataLayer.push({
            event: "add_payment_info",
            ecommerce: {
                currency: "EUR",
                value: this.order.totalAmount,
                payment_type: paymentType,
                items: dataLayerProducts
            }
        });
    }
}
