export default class OrderReturnRequest {
  public orderId: string = "";
  public firstName: string = "";
  public lastName: string = "";
  public fromEmail: string = "";
  public productId: string = "";
  public quantity: number = 0;
  public motive: string;
  public comments?: string;
}
