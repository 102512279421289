import Component, { mixins } from "vue-class-component";
import BrandtVue from "../common/brandt-vue";
import AccessoryCategoryMenuMixin from "./accessory-category-menu.mixin";

if (document.querySelector(".AccessoryCategoryListPage")) {

    @Component({})
    class AccessoryCategoryListPage extends mixins(BrandtVue, AccessoryCategoryMenuMixin) {

    }

    const accessoryCategoryListPage = new AccessoryCategoryListPage({ el: ".AccessoryCategoryListPage" });

}
