import apiClient from "../common/services/api.client";
import Country from "./step/country";
import CreateOrder from "./step/create-order";

declare var locale: string;

/**
 * This service aims to handle all informations linked to the account of the current user.
 */
export class OrderService {
    public async getCountries(): Promise<Country[]> {
        return apiClient.get<Country[]>(`/countries?locale=${locale}`);
    }

    public async saveOrder(order: CreateOrder): Promise<CreateOrder> {
        return apiClient.post<CreateOrder, any>("/orders", order);
    }

    public async getOrder(orderId: string): Promise<CreateOrder> {
        return apiClient.get<CreateOrder>(`/orders/${orderId}`);
    }
}

export default new OrderService();
