import { CountryCode } from "./country-code";

export class ZipCodeValidator {

  public validate(zipCode: string, countryCode: string) {
    if (!zipCode || !countryCode || Object.keys(CountryCode).indexOf(countryCode) < 0) {
      return false;
    }

    const regex = /[^\s\-A-Za-z0-9]/;
    if (regex.test(zipCode.trim())) {
      return false;
    }

    switch (countryCode) {
    case CountryCode.AT:
        return this.isValid(/^([1-9][0-9]{3})$/, zipCode);
    case CountryCode.CH:
        return this.isValid(/^([1-8][0-9]|9[0-6])[0-9]{2}$/i, zipCode);
    case CountryCode.DE:
        return this.isValid(/^(0[1-9]|[1-9][0-9])[0-9]{3}$/, zipCode);
    case CountryCode.ES:
        return this.isValid(/^(0[1-9]|[1-4][0-9]|[1-5][0-2])[0-9]{3}$/, zipCode);
    case CountryCode.FR:
        return this.isValid(/^(0[1-9]|[1-8][0-9]|9[0-5])[0-9]{3}$/, zipCode);
    case CountryCode.GB:
        return this.isValidGBZipCode(zipCode);
    case CountryCode.IE:
        return this.isValid(/^([AC-FGHKNPRTV-Y]\d{2}|D6W)[0-9AC-FGHKNPRTV-Y]{4}$/, zipCode);
    case CountryCode.PT:
        return this.isValid(/^([1-9][0-9]{3})([-])([0-9]{3})$/, zipCode);
    case CountryCode.PL:
        return this.isValid(/^([0-9]{2})([-])([0-9]{3})$/, zipCode);
    case CountryCode.CZ:
    case CountryCode.SK:
        return this.isValid(/^([1-9][0-9]{2})(\s?)([0-9]{2})$/, zipCode);
    default:
        return true;
    }
  }

  private isValidGBZipCode(zipCode: string): boolean {
    const alpha1 = "[abcdefghijklmnoprstuwyz]"; // Character 1.
    const alpha2 = "[abcdefghklmnopqrstuvwxy]"; // Character 2.
    const alpha3 = "[abcdefghjkpstuw]";         // Character 3 == ABCDEFGHJKPSTUW.
    const alpha4 = "[abehmnprvwxy]";            // Character 4 == ABEHMNPRVWXY.
    const alpha5 = "[abdefghjlnpqrstuwxyz]";    // Character 5 != CIKMOV.

    const regexList: RegExp[] = [];
    // Expression for postcodes: AN NAA, ANN NAA, AAN NAA, and AANN NAA.
    regexList.push(new RegExp("^(" + alpha1 + "{1}" + alpha2 + "{0,1}[0-9]{1,2})([0-9]{1}" + alpha5 + "{2})$"));
    // Expression for postcodes: ANA NAA.
    regexList.push(new RegExp("^(" + alpha1 + "{1}[0-9]{1}" + alpha3 + "{1})([0-9]{1}" + alpha5 + "{2})$"));
    // Expression for postcodes: AANA NAA.
    regexList.push(new RegExp("^(" + alpha1 + "{1}" + alpha2 + "[0-9]{1}" + alpha4 + ")([0-9]{1}" + alpha5 + "{2})$"));
    // Exception for the special postcode GIR 0AA.
    regexList.push(new RegExp("^(gir)(0aa)$"));
    // Standard BFPO numbers.
    regexList.push(new RegExp("^(bfpo)([0-9]{1,4})$"));
    // c/o BFPO numbers.
    regexList.push(new RegExp("^(bfpo)(c\/o[0-9]{1,3})$"));
    // Load up the string to check, converting into lowercase and removing spaces.

    zipCode = zipCode.replace(/ /g, "").toLocaleLowerCase();

    for (const regex of regexList) {
      if (this.isValid(regex, zipCode)) {
          return true;
      }
    }

    return false;
  }

  private isValid(regex: RegExp, zipCode: string): boolean {
    return regex.test(zipCode);
  }
}

export default new ZipCodeValidator();
