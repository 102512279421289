import Vue from "vue";
import Component from "vue-class-component";
import Notification from "../../common/ui/notification";
import { AlertType } from "./alert-type";
import AlertComponent from "./alert.component.vue";
import "./notification.mixin.scss";

@Component({
  components: {
    alert: AlertComponent,
  },
})
export default class NotificationMixin extends Vue {

  public notificationStack: Notification[] = [];
  public message: string = null;
  private currentTimer: number;

  public created() {
    this.$on("notification", (notification: Notification) => {
      this.addNotification(notification);
      this.message = notification.message;
    });
  }

  public getLastNotification() {
    if (this.notificationStack.length > 0) {
      return this.notificationStack[this.notificationStack.length - 1];
    }
  }

  private addNotification(notification: Notification) {
    this.notificationStack.push(notification);
    this.clearLastNotification();
  }

  private clearLastNotification() {
    if (this.currentTimer) {
      clearTimeout(this.currentTimer);
    }

    if (this.notificationStack.length > 0 && this.notificationStack[this.notificationStack.length - 1].timeout) {
      this.currentTimer = setTimeout(() => {
        this.notificationStack.pop();
        this.clearLastNotification();
      }, this.notificationStack[this.notificationStack.length - 1].timeout);
    }
  }

}
