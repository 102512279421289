import Vue from "vue";
import Component from "vue-class-component";
import { EndProductProduct } from "./end-product-product";
import endProductService from "./end-product.service";
import { ProductType } from "./end-product-type";
import _ from "lodash";
import PdfModal from "../common/ui/pdf-modal.component.vue";

@Component({
    components: {
        "pdf-modal": PdfModal,
    },
})
export class EndProductProductSearchingMixin extends Vue {
    public searchSubmitting = false;
    public errorCodeKey: string = null;
    public position: number = null;
    public endProductProducts: EndProductProduct[] = [];
    public endProductReference: string;
    public searchByPositionComplete = false;
    public pdfModalShown = false;
    public selectedFileUrl: { [key: string]: string } = { value: null };

    public async searchProductsForPosition() {
        this.searchSubmitting = true;
        this.errorCodeKey = null;
        if (!this.position) {
            this.endProductProducts = [];
        } else {
            try {
                await this.doSearchProducts();

                if (this.endProductProducts.length === 0) {
                    this.errorCodeKey =
                        "end-product.detail.no-position-results";
                } else {
                    this.scrollToProducts();
                }
            } catch (error) {
                this.errorCodeKey = "common.error-code.server";
            }
        }
        this.searchSubmitting = false;
        this.searchByPositionComplete = true;
    }

    public async viewAllProducts() {
        this.endProductProducts = [];
        this.scrollToProducts();
    }

    public showPdfModal(fileUrl: string) {
        this.selectedFileUrl.value = fileUrl;
        this.pdfModalShown = true;
    }

    private scrollToProducts() {
        window.scrollTo(0, this.getOffsetTop() - 100);
    }

    private getOffsetTop() {
        const el: any = this.$el.getElementsByClassName(
            "EndProductDetailPage-parts"
        )[0];

        const rect = el.getBoundingClientRect();
        const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;

        return rect.top + scrollTop;
    }

    private async doSearchProducts() {
        const requestPromises = [];
        const productTypes = _.values(ProductType);
        for (const productType of _.filter(productTypes, pt => pt != ProductType.Assistance)) {
            requestPromises.push(
                endProductService.findProductsForPosition(
                    this.endProductReference,
                    this.position,
                    productType as ProductType
                )
            );
        }
        this.endProductProducts = (await Promise.all(requestPromises)).reduce(
            (epp, subEpp) => epp.concat(subEpp),
            []
        );
    }
}
