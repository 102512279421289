



















import Component from "vue-class-component";
import Vue from "vue";

@Component({
    props: {
        small: {
            type: Boolean,
            default: false
        }
    }
})
export default class SpinnerComponent extends Vue {
    public small: boolean;
}
