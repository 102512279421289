import Component from "vue-class-component";
import BrandtVue from "../common/brandt-vue";
import reCaptchaService from "../common/services/re-captcha.service";

if (document.querySelector(".ContactPage")) {

  @Component({})
  class ContactPage extends BrandtVue {

    public async requestContactInfo() {
      const token = await reCaptchaService.getSiteToken("contact");
      window.location.href = window.location.href + "?reCaptchaResponse=" + token;
    }

  }

  const contactPage = new ContactPage({ el: ".ContactPage" });

}
