
























































import Component, {mixins} from "vue-class-component";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import FieldComponent from "../common/ui/field.component.vue";
import authenticationService from "../common/authentication/authentication.service";
import AlertComponent from "../common/ui/alert.component.vue";
import {Credentials} from "../common/authentication/credentials";
import VueRouter from "vue-router";
import SpinnerComponent from "../common/ui/spinner.component.vue";
import {ROUTES} from "./login.page";

declare var redirectTo: string;
declare var dataLayer: any;

@Component({
  components: {
    spinner: SpinnerComponent,
    alert: AlertComponent,
    field: FieldComponent
  },
  validations: {
    credentials: {
      email: {
        required
      },
      password: {
        required
      }
    }
  }
} as any)
export default class OrderPersonalDataLoginComponent extends mixins(
  validationMixin
) {
  public $router: VueRouter;
  public isOpen = false;
  public credentials: Credentials = {
      email: null,
      password: null
  };
  public errorMessageKey: string = null;
  public isLoading = false;
  public forgottenPasswordLink = ROUTES.FORGOTTEN_PASSWORD;

  public showFormLogin() {
    if (this.isOpen) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }
  }

  public async login() {
      this.isLoading = true;
    this.errorMessageKey = null;
    try {
      await authenticationService.login(this.credentials);
      window.location.href = redirectTo;

      dataLayer.push({
        'event': 'interactionDone',
        'categoryGA': 'account',
        'actionGA': 'loginOK',
        'labelGA': 'insideCheckout'
      });
    } catch (error) {
      if (error.response && error.response.status) {
        switch (error.response.status) {
          case 400:
          case 401:
            this.errorMessageKey = "login-form.bad-credentials-error";
            break;
        }
      }
      if (!this.errorMessageKey) {
        this.errorMessageKey = "common.error-code.server";
      }
      dataLayer.push({
        'event': 'interactionDone',
        'categoryGA': 'account',
        'actionGA': 'loginFail',
        'labelGA': 'insideCheckout'
      });
    }
    this.isLoading = false;
  }
}
