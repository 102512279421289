import {AssistanceType} from './assistance-type.enum';
import {Timeslot} from "../../common/models/timeslot.model";
import EndProduct from "../../end-product/end-product";

declare var locale: string;

export interface AssistanceState {
    assistanceType: AssistanceType;
    isLoading: boolean;
    diagnostic: boolean;
    selectedTimeslot: Timeslot;
    spareParts: boolean;
    endProduct: EndProduct;
}

export class AssistanceStore {
    private _state: AssistanceState = {
        isLoading: false,
        assistanceType: undefined,
        selectedTimeslot: undefined,
        diagnostic: false,
        spareParts: false,
        endProduct: undefined
    };

    public get state() {
        return this._state;
    }

    public get endProduct() {
        return this._state.endProduct;
    }

    public get assistanceType(){
        return this._state.assistanceType;
    }

    public setDiagnostic(possessed: boolean) {
        this._state.diagnostic = possessed;
    }

    public setSpareParts(possessed: boolean) {
        this._state.spareParts = possessed;
    }

    public setSelectedTimeslot(timeslot: Timeslot) {
        this._state.selectedTimeslot = timeslot;
    }

    public setAssistanceType(type: AssistanceType) {
        this._state.assistanceType = type;
    }

    public setEndProduct(endProduct: EndProduct){
        this._state.endProduct = endProduct;
    }
}

export default new AssistanceStore();
