

































import Vue from "vue";
import Component from "vue-class-component";

declare var youtubeImagePath: string;

@Component({})
export default class AsideCtaYoutubeComponent extends Vue {
    youtubeImagePath: string = youtubeImagePath;

    youtubeRedirection(): void {
        window.open(
            "https://www.youtube.com/channel/UC5_Qc04MMsbS1KBZUMjT3VA",
            "_blank"
        );
    }
}
