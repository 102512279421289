import VueI18n from "vue-i18n";
import moment from "moment-timezone";

import * as en from "../../static-src/locale/messages_en.json";
import * as fr from "../../static-src/locale/messages_fr.json";
import * as es from "../../static-src/locale/messages_es.json";

export class I18nService {

  private i18n: VueI18n = null;

  public getI18n(): VueI18n {
    if (!this.i18n) {
      this.i18n = new VueI18n({
        locale: locale.substring(0, 2),
        messages: {
          fr: fr.default, en: en.default, es: es.default,
        },
        dateTimeFormats: {
          fr: {
            short: {
              year: "numeric", month: "short", day: "numeric"
            }
          }
        },
        numberFormats: {
          fr: {
            currency: {
              style: "currency", currency: "EUR",
            },
          },
          es: {
            currency: {
              style: "currency", currency: "EUR",
            },
          },
          en: {
            currency: {
              style: "currency", currency: "EUR",
            },
          },
        },
      });
      moment.locale(this.i18n.locale);
    }
    return this.i18n;
  }

}

export default new I18nService();
