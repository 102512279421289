import * as _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import VueRouter, { Route, RouteConfig } from "vue-router";
import authenticationService from "../../common/authentication/authentication.service";
import BrandtVue from "../../common/brandt-vue";
import i18nService from "../../common/i18n.service";
import AlertComponent from "../../common/ui/alert.component.vue";
import SpinnerComponent from "../../common/ui/spinner.component.vue";
import OrderPaymentComponent from "./order-payment.component.vue";
import OrderPreferenceComponent from "./order-preference.component.vue";
import orderStore from "./order.store";
import QuoteTunnelComponent from "./quote-tunnel.component.vue";

const i18n = i18nService.getI18n();

const PATHS = {
    STEP: i18n.t("order.paths.step") as string,
    PREFERENCES: i18n.t("order.paths.preferences") as string,
    PAYMENT: i18n.t("order.paths.payment") as string
};

export const ROUTES = {
    PREFERENCES: `/${PATHS.STEP}/${PATHS.PREFERENCES}`,
    PAYMENT: `/${PATHS.STEP}/${PATHS.PAYMENT}`
};

if (document.querySelector(".OrderPage")) {
    Vue.use(VueRouter);

    const routes: RouteConfig[] = [
        {
            path: `/${PATHS.STEP}`, component: QuoteTunnelComponent, children: [
                { path: PATHS.PREFERENCES, component: OrderPreferenceComponent },
                {
                    path: PATHS.PAYMENT,
                    component: OrderPaymentComponent,
                    beforeEnter: (to: Route, from: Route, next: any) => {
                        if (!orderStore.state.termsAndConditionsAccepted
                            || orderStore.state.paymentMethods.length === 0) {
                            const newRoute: any = { path: ROUTES.PREFERENCES };
                            if (to.query.orderId) {
                                newRoute.query = { orderId: to.query.orderId };
                            } else if (from.query.orderId) {
                                newRoute.query = { orderId: from.query.orderId };
                            }
                            next(newRoute);
                        } else {
                            next();
                        }
                    }
                }
            ]
        },
        { path: `/${PATHS.STEP}*`, redirect: `/${PATHS.STEP}/${PATHS.PREFERENCES}` },
        { path: "*", redirect: `/${PATHS.STEP}/${PATHS.PREFERENCES}` }
    ];

    const router = new VueRouter({
        routes,
    });

    @Component({
        router,
        components: {
            spinner: SpinnerComponent,
            alert: AlertComponent,
        }
    })
    class OrderPage extends BrandtVue {

        public $t: any;
        public state = orderStore.state;

        public menuRoutes = {
            preferences: ROUTES.PREFERENCES,
            payment: ROUTES.PAYMENT
        };

        public links: { string: string };

        public created() {
            (async () => {
                if (authenticationService.isAuthenticated()) {
                    const promises = [
                        orderStore.getCountries(),
                        orderStore.getAddresses(),
                        orderStore.getCart()
                    ];
                    await Promise.all(promises);
                }
            })();
        }

    }

    const orderPage = new OrderPage({ el: ".OrderPage" });

}
