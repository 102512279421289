























































import Vue from "vue";
import Component from "vue-class-component";
import AssistanceFormulaireComponent from "./formulaire.form.component.vue";
import AssistanceRDVService from "../assistance/assistance-rdv.service";
import SpinnerComponent from "../common/ui/spinner.component.vue";
import AlertComponent from "../common/ui/alert.component.vue";
import {AssistanceType} from "../assistance/step/assistance-type.enum";

declare var name: string;
declare var firstname: string;
declare var email: string;
declare var phone: string;
declare var assistanceRdvs: any;

@Component({
    components: {
        "assistance-form": AssistanceFormulaireComponent,
        spinner: SpinnerComponent,
        alert: AlertComponent,
    }
})
export default class FormulaireViewComponent extends Vue {
    public name = name;
    public phone = phone;
    public firstname = firstname;
    public email = email;
    public assistanceRdvs = assistanceRdvs;

    public isFormInvalid: boolean = true;

    public resultForm: any;

    private submitting = false;
    private error: any = null;

    // truth source about current form
    public formDisplayCounter = 0;
    public isDiagnostic: boolean = true;

    mounted(){
        this.updateTitle();
        this.resultForm = {
            "name": this.name,
            "firstname": this.firstname,
            "phone": this.phone,
            "email": this.email,
            "brand": "",
            "reference": "",
            "displayedCode": "",
            "setupDate": "",
            "comment": "",
            "serialNumber": ""
        }
    }

    updateTitle(){
        this.isDiagnostic = assistanceRdvs[this.formDisplayCounter].assistanceRef === AssistanceType.DIAGNOSTIC;
    }

    updateIsFormInvalid(event: any){
        this.isFormInvalid = event;
    }

    updateForm(event: any){
        this.resultForm = event;
    }

    goToPrecedentForm(){
        // decrement counter
        this.formDisplayCounter --;
        // data that will be updated
        var currentAssistanceId = assistanceRdvs[this.formDisplayCounter].assistanceId;
        // ugly cast because this.$refs[currentAssistanceId] gives VueElement[] and
        // gulp says that it really gives Vue | Element | Vue[] | Element[]
        (this.$refs[currentAssistanceId] as any[])[0].checkForm();
        this.updateTitle();
        window.scrollTo(0, 0);
    }

    redirect(){
        this.submitting = true;
        this.updateAssistanceRDV().then(() => {
            if (this.assistanceRdvs.length - 1 === this.formDisplayCounter){
                this.$emit("display-recap");
            }else{
                this.isFormInvalid = true;
                this.formDisplayCounter ++;
                this.updateTitle();
                window.scrollTo(0, 0);
            }
        }).catch((e) => {
            this.error = e
        }).finally(() => {
            this.submitting = false;
        });
    }

    async updateAssistanceRDV() {
        var assistanceRDV = {
                assistanceRdvId: this.resultForm.assistanceId,
                firstName: this.resultForm.firstname,
                lastName: this.resultForm.name,
                phoneNumber: this.resultForm.phone,
                email: this.resultForm.email,
                firstUseDate: this.resultForm.setupDate,
                errorCodeDisplayed: this.resultForm.displayedCode,
                otherComments: this.resultForm.comment,
                serialNumber: this.resultForm.serialNumber
        }
        await AssistanceRDVService.updateAssistanceRDV(assistanceRDV);
    }


}
