









































































import _ from "lodash";
import Vue from "vue";
import Page from "../models/page";
import Product from "../../product/product";
import Component, { mixins } from "vue-class-component";
import { Slide, Carousel } from "vue-carousel";
import TutorialCarouselMixin from "../tutorial-carousel.mixin";
import ProductCardComponent from "../../product/product-card.component.vue";
import crossSellingService from "./cross-selling.service";
import { CrossSellingRequestType } from "./cross-selling-request-type.enum";
import SpinnerComponent from "../ui/spinner.component.vue";
import { CartCrossSellingRequest } from "./cart-cross-selling-request";
import CrossSellingRequest from "./cross-selling-request";

@Component({
  props: {
    title: {
      type: String,
      required: true
    },
    'is-assistance': { // isAssistance dans $props
      type: Boolean,
      required: false,
      default: false
    },
    'assistance-id': {
      type: String,
      required: false,
      default: undefined
    }
  },
  components: {
    slide: Slide,
    carousel: Carousel,
    "product-card": ProductCardComponent,
    spinner: SpinnerComponent
  }
})
export default class CrossSellingComponent extends mixins(
  TutorialCarouselMixin
) {
  public productPage: Page<any> = null;
  public loading = true;

  public addProductToCart(product: any) {
    this.$emit("add-product-to-cart", { product, done: async () => {
      this.loadCrossSellingProducts();
    }});
  }

  public mounted() {
    (async () => {
      this.loadCrossSellingProducts();
    })();
  }

  private async loadCrossSellingProducts() {
    try {
      let request: CrossSellingRequest;
      if(this.$props.isAssistance){
        request = crossSellingService.createCartCrossSellingRequestByAssistance({
          type: CrossSellingRequestType.ASSISTANCE,
          pageable: { pageNumber: 0, pageSize: 10 }
        });
      } else if (this.$props.assistanceId === undefined){
        request = crossSellingService.createCartCrossSellingRequest({
          type: CrossSellingRequestType.CART,
          pageable: { pageNumber: 0, pageSize: 10 }
        });
      } else {
        request = crossSellingService.createCartCrossSellingRequestByAccountAssistance({ 
          type: CrossSellingRequestType.ACCOUNT_ASSISTANCE,
          pageable: { pageNumber: 0, pageSize: 10 },
        }, this.$props.assistanceId);
      }
      this.productPage = await crossSellingService.getCrossSellingProducts(request)
    } catch (err) {
    } finally {
      this.loading = false;
    }
  }
}
