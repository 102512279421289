



















































































































































































import Component, {mixins} from "vue-class-component";
import {validationMixin} from "vuelidate";
import {email, minLength, required} from "vuelidate/lib/validators";
import {AlertType} from "../ui/alert-type";
import AUTHENTICATION_CONSTANTS from "./authentication.constants";
import authenticationService from "./authentication.service";
import RegisterData from "./register-data";
import reCaptchaService from "../services/re-captcha.service";
import dqeService, {DqeStatus} from "../dqe/dqe.service";
import SpinnerComponent from "../ui/spinner.component.vue";
import AlertComponent from "../ui/alert.component.vue";

declare var dataLayer: any;
declare var privacyPolicyLink: string;

@Component({
  components: {
    alert: AlertComponent,
    spinner: SpinnerComponent
  },
  props: {
    action: String
  },
  validations: {
    account: {
      lastName: {
        required,
        minLength: minLength(2)
      },
      firstName: {
        required,
        minLength: minLength(2)
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      }
    },
    passwordRepeat: {
      required,
      passwordRepeat: function(value: string) {
        return this.account.password === value;
      }
    }
  }
} as any)
export default class RegisterComponent extends mixins(validationMixin) {
  public privacyPolicyLink = privacyPolicyLink;
  public DqeStatus = DqeStatus;
  public $t: any;
  public ERROR_CODE = {
    SERVER: "SERVER",
    USER_EXISTS: "USER_EXISTS"
  };
  public ACTION = AUTHENTICATION_CONSTANTS.ACTION;
  public verificationPending = false;
  public dqeEmailResult: any = null;

  public passwordRepeat: string = null;
  public marketingOptin: boolean = false;
  public account: RegisterData = {
    lastName: null,
    firstName: null,
    email: null,
    password: null,
    reCaptchaResponse: null,
    marketingOptin: null
  };
  public submitting = false;

  public async submit() {
    this.submitting = true;

    this.account.reCaptchaResponse = await reCaptchaService.getSiteToken(
      "registration"
    );

    try {
      this.account.marketingOptin = this.marketingOptin;
      await authenticationService.register(this.account);
      dataLayer.push({
        event: "interactionDone",
        categoryGA: "account",
        actionGA: "creationOK",
        labelGA: "outsideCheckout"
      });

      this.submitting = false;
      this.changeAction(AUTHENTICATION_CONSTANTS.ACTION.LOGIN);
    } catch (error) {
      dataLayer.push({
        event: "interactionDone",
        categoryGA: "account",
        actionGA: "creationFail",
        labelGA: "outsideCheckout"
      });

      let errorCodeKey: string;
      if (error.response && error.response.data) {
        if (error.response.data.code == "USER_EXISTS") {
          errorCodeKey = "login-form.user-exists-error";
        }
      }

      if (!errorCodeKey) {
        errorCodeKey = "login-form.server-error";
      }

      this.$emit("show-alert-requested", {
        type: AlertType.Error,
        message: this.$t(errorCodeKey)
      });
      this.submitting = false;
    }
  }
  public stopPropagation($event: Event) {
    $event.stopPropagation();
  }

  public changeAction(action: string) {
    this.$emit("change-action", action);
  }

  public getErrorMessage(validation: any) {
    if (validation.passwordRepeat === false) {
      return this.$t("common.error.field-password-repeat");
    }
    if (validation.minLength === false) {
      return this.$t("common.error.field-min-length", [6]);
    }
    if (validation.email === false) {
      return this.$t("common.error.field-email");
    }
    return this.$t("common.error.field-required");
  }

  public async verifyEmail() {
    this.verificationPending = true;
    this.dqeEmailResult = await dqeService.checkEmail(this.account.email);
    this.verificationPending = false;
  }
}
