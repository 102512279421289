import * as _ from "lodash";
import IAsyncCommand from "../common/command";
import { Product } from "../common/models/product";
import { Cart } from "./cart";
import { CartItem } from "./cart-item";
import cartService from "./cart.service";

export default class AddCartItemCommand implements IAsyncCommand {
    private _isDone = false;
    private _cart: Cart;

    private quantityBeforeUpdate: number;

    get isDone() {
        return this._isDone;
    }

    get cart() {
        return this._cart;
    }

    constructor(private product: Product) {}

    public async do() {
        if (this.isDone) {
            return;
        }
        this._cart = await cartService.upsertProduct(
            this.product.id,
            null,
            null,
            null
        );
        this._isDone = true;
    }

    public async undo() {
        return;
    }
}
