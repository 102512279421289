



























































import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import FieldComponent from "../common/ui/field.component.vue";
import authenticationService from "../common/authentication/authentication.service";
import AlertComponent from "../common/ui/alert.component.vue";
import { Credentials } from "../common/authentication/credentials";
import VueRouter from "vue-router";
import SpinnerComponent from "../common/ui/spinner.component.vue";
import { ResetPasswordData } from "../common/authentication/reset-password-data";
import { ROUTES } from "./login.page";

@Component({
  components: {
    spinner: SpinnerComponent,
    alert: AlertComponent,
    field: FieldComponent
  },
  validations: {
    email: {
      required,
      email
    }
  }
} as any)
export default class OrderPersonalDataFogottenPasswordComponent extends mixins(
  validationMixin
) {
  public $router: VueRouter;
  public loginLink = ROUTES.LOGIN;
  public email: string = null;
  public errorMessageKey: string = null;
  public successMessageKey: string = null;
  public isLoading = false;

  public async resetPassword() {
    this.isLoading = true;
    this.successMessageKey = null;
    this.errorMessageKey = null;
    try {
      await authenticationService.resetPassword({ email: this.email });
      this.successMessageKey = 'forgot-password.response-alert';
    } catch (error) {
      this.errorMessageKey = 'common.error-code.server';
    } finally {
      this.isLoading = false;
    }
  }
}
