





























import Vue from "vue";
import Component from "vue-class-component";
import PersonalDataComponent from "./personal-data.component.vue";
import ChangePasswordComponent from "./change-password.component.vue";

@Component({
  components: {
    "personal-data": PersonalDataComponent,
    "change-password": ChangePasswordComponent,
  },
  props: {
    account: Object,
    context: Object
  }
})
export default class ProfileComponent extends Vue {

  public context: any;

}
