import { render, staticRenderFns } from "./alert-product-modal.component.vue?vue&type=template&id=543051c6&"
import script from "./alert-product-modal.component.vue?vue&type=script&lang=ts&"
export * from "./alert-product-modal.component.vue?vue&type=script&lang=ts&"
import style0 from "./alert-product-modal.component.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "alert-product-modal.component.vue"
export default component.exports