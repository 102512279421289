import Component from "vue-class-component";
import cartService from "../cart/cart.service";
import BrandtVue from "../common/brandt-vue";
import FormulaireViewComponent from "./formulaire-view.component.vue";

declare var hasAssistance: boolean;

if (document.querySelector(".PaymentPage")) {
    
    @Component({
        components: {
            "assistance-view": FormulaireViewComponent
        }
    })
    class PaymentPage extends BrandtVue {

        public created() {
            cartService.removeCartId();
        }
    }
    const paymentPage = new PaymentPage({
        el: ".PaymentPage"
    });
}
