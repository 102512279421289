import Vue from "vue";
import Component from "vue-class-component";
import {Cart} from "./cart";
import {CartItem} from "./cart-item";
import cartService from "./cart.service";
import DeleteCartItemCommand from "./delete-cart-item-command";

declare var dataLayer: any;

@Component({})
export default class CartDetailMixin extends Vue {
    public cart: Cart = null;
    public isLoading = false;
    public loadingItemIndex = -1;

    private deleteHistory: DeleteCartItemCommand[] = [];

    public get lastDeletedItem() {
        if (this.deleteHistory.length <= 0) {
            return;
        }
        return this.deleteHistory[this.deleteHistory.length - 1].item;
    }

    public get canRollbackDelete() {
        return this.deleteHistory.length > 0;
    }

    public get cartIsEmpty() {
        return !this.cart || !this.cart.items || this.cart.items.length === 0;
    }

    public created() {
        this.isLoading = true;
        (async () => {
            await this.refreshCart();
        })();
    }

    public async updateCart() {
        await this.refreshCart(
            await cartService.updateCart(this.cart)
        );
    }

    public async rollbackDelete() {
        const command = this.deleteHistory[this.deleteHistory.length - 1];
        await this.refreshCart(await command.undo());
        this.deleteHistory.splice(this.deleteHistory.length - 1, 1);
    }

    public async decrementQuantity(item: CartItem) {
        if (item && item.quantity > 1) {
            const quantity = item.quantity - 1;
            this.loadingItemIndex = this.cart.items.indexOf(item);

            await this.refreshCart(
                await cartService.upsertProduct(item.product.id, quantity, null, item.assistanceRdv)
            );
            this.gtmStatCartItem(item, false, false);
        }
    }

    public async incrementQuantity(item: CartItem) {
        if (item && item.product.type !== 'ASSISTANCE') {
            const quantity = item.quantity + 1;
            this.loadingItemIndex = this.cart.items.indexOf(item);

            await this.refreshCart(
                await cartService.upsertProduct(item.product.id, quantity, null, item.assistanceRdv)
            );

            this.gtmStatCartItem(item, true, false);
        }
    }

    public async removeItem(item: CartItem) {
        if (!this.cart) {
            return;
        }
        this.loadingItemIndex = this.cart.items.indexOf(item);
        const deleteCommand = new DeleteCartItemCommand(item, this.cart);
        await this.refreshCart(await deleteCommand.do());
        if (item.assistanceRdv == null){
            this.deleteHistory.push(deleteCommand);
        }
        this.gtmStatCartItem(item, false, true);
    }

    protected async refreshCart(cartToUse?: Cart) {
        this.isLoading = !cartToUse;
        this.cart = !!cartToUse ? cartToUse : await cartService.getCart();
        this.isLoading = false;
        this.loadingItemIndex = -1;
        const totalCount = this.cart.items.reduce(
            (total, item) => item.quantity + total,
            0
        );
        if ((this as any).cartItemCount) {
            (this as any).cartItemCount = totalCount;
        } else {
            this.$emit("refresh-cart", { cartItemCount: totalCount, cart: this.cart });
        }
        this.cartLoaded();
    }

    protected cartLoaded() {
        // Handler used by components using this mixin
    }

    private gtmStatCartItem(item: CartItem, added: boolean, completeRemoval: boolean) {
        if (added) {
            dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                    currency: "EUR",
                    value: item.product.price.value,
                    items: [{
                        item_id: item.product.reference,
                        item_name: item.product.title,
                        quantity: 1,
                        price: item.product.price.value,
                    }]
                }
            });
        } else {
            dataLayer.push({
                event: "remove_from_cart",
                ecommerce: {
                    currency: "EUR",
                    value: item.product.price.value,
                    items: [{
                            item_id: item.product.reference,
                            item_name: item.product.title,
                            quantity: completeRemoval ? item.quantity : 1,
                            price: item.product.price.value,
                    }]
                }
            });
        }
    }
}
