



























































































import Vue from "vue";
import Component from "vue-class-component";

@Component({
    name: "slider",
})
export default class SliderComponent extends Vue { }
