










































































import Vue from "vue";
import Component from "vue-class-component";
import { Product } from "../common/models/product";

declare var orderLink: string;
declare var staticUrl: string;

@Component({
  props: {
    addedProduct: {
      type: Object,
      required: true
    }
  }
})
export default class ProductAddedModalComponent extends Vue {
  public noImageSrc = staticUrl + "/pictures/no-image.png";
  public addedProduct: Product;
  public finalizeOrderLink = orderLink;
}
