import Component, {mixins} from "vue-class-component";
import BrandtVue from "../common/brandt-vue";
import BeCalledBackMixin from "../common/be-called-back.mixin";

if (document.querySelector(".BrandLandingPageLayoutPage")) {
    @Component({})
    class BrandLandingPageLayoutPage extends mixins(BrandtVue, BeCalledBackMixin) {

    }

    const page = new BrandLandingPageLayoutPage({ el: ".BrandLandingPageLayoutPage" });
}
