import moment from "moment-timezone";

export class momentDateFormatService {

    /**
     * Affiche la date d'un objet moment
     * @param str
     */
    ISO8601StringToMomentToString(date: string | Date): string{
        return moment(date).format();
    }
}

export default new momentDateFormatService();