import Component, { mixins } from "vue-class-component";
import BrandtVue from "../common/brandt-vue";
import TutorialCarouselMixin from "../common/tutorial-carousel.mixin";
import EndProductCategoryListMixin from "./end-product-category-list.mixin";
import "./end-product-category-list.page.scss";
import PartFinderMixin from "./part-finder.mixin";

if (document.querySelector(".EndProductCategoryListPage")) {

  @Component({})
  class EndProductLandingPage extends mixins(BrandtVue, PartFinderMixin,
    TutorialCarouselMixin, EndProductCategoryListMixin) {

  }

  const endProductLandingPage = new EndProductLandingPage({ el: ".EndProductCategoryListPage" });

}
