import Vue from "vue";
import Component from "vue-class-component";
import VueRouter, {Route, RouteConfig} from "vue-router";
import BrandtVue from "../../common/brandt-vue";
import i18nService from "../../common/i18n.service";
import AlertComponent from "../../common/ui/alert.component.vue";
import SpinnerComponent from "../../common/ui/spinner.component.vue";
import AssistanceChoiceComponent from "./assistance-choice.component.vue";
import AssistanceSearchComponent from "./assistance-search.component.vue";
import AssistanceTimeslotSelectionComponent from "./assistance-timeslot-selection.component.vue";
import AssistanceAddToCartComponent from "./assistance-add-to-cart.component.vue";
import AssistanceVisioOrTechnicComponent from "./assistance-choice-visio.component.vue";
import '../assistance-shared.scss';
import assistanceStore from "./assistance.store";
import endProductService from "../../end-product/end-product.service";
import EndProduct from "../../end-product/end-product";
import {AssistanceType} from "./assistance-type.enum";

const i18n = i18nService.getI18n();

export const ROUTES = {
    SEARCH: `/search`,
    SELECT_VISIO: `/type`,
    CHOICE: `/choice`,
    TIMESLOT: '/creneaux',
    ADD_TO_CART: '/ajouter',
};

if (document.querySelector(".AssistancePage")) {
    Vue.use(VueRouter);

    const routes: RouteConfig[] = [
        {
            path: `${ROUTES.SEARCH}`,
            component: AssistanceSearchComponent,
        },
        {
            path: `${ROUTES.SELECT_VISIO}`,
            component: AssistanceVisioOrTechnicComponent,
            beforeEnter: async (to, from, next) => {
                await checkRef(to, from, next);
                next();
            }
        },
        {
            path: `${ROUTES.CHOICE}`,
            component: AssistanceChoiceComponent,
            beforeEnter: async (to, from, next) => {
                await checkRef(to, from, next);
                next();
            }
        },
        {
            path: `${ROUTES.TIMESLOT}`,
            component: AssistanceTimeslotSelectionComponent,
            beforeEnter: async (to, from, next) => {
                await checkRef(to, from, next);
                checkAssistance(to, from, next);
                next();
            }
        },
        {
            path: `${ROUTES.ADD_TO_CART}`,
            component: AssistanceAddToCartComponent,
            beforeEnter: async (to, from, next) => {
                await checkRef(to, from, next);
                checkAssistance(to, from, next);
                next();
            }
        },
        { path: "*", redirect: `${ROUTES.SEARCH}` }
    ];

    var checkRef = async function checkReferenceQueryParams(to:Route, from:Route, next:Function){
        // check if the query params contains the value 'reference'
        // and if the store as a defined value for the endproduct
        if (!!to.query.reference && typeof assistanceStore.state.endProduct === "undefined"){
            // if this is the case, we question the Product service to know the if the given reference is valid
            var query = <string>to.query.reference;
            var result = await endProductService.findSlug(query);
            // if the given reference is invalid, we redirect to the search page
            if (typeof result === "undefined"){

                next({path: ROUTES.SEARCH});
            // if the given reference is valid, we add it to the store
            }else{
                // place the found product in the store
                var endProduct: EndProduct = result;
                assistanceStore.setEndProduct(endProduct);
            }
        }
    }

    var checkAssistance = function checkAssistanceQueryParams(to:Route, from:Route, next:Function){
        // check if the query params contains value
        if (!!to.query.assistanceType && typeof assistanceStore.state.assistanceType === "undefined"){
            // if this is the case, we verify if the assistanceType given is valid
            var assistanceType = <string>to.query.assistanceType;
            // if the given assistanceType, we redirect to the search page
            if(assistanceType != AssistanceType.DIAGNOSTIC && assistanceType != AssistanceType.TECHNICAL){

                next({path: ROUTES.SEARCH});
            // if the given assistanceType, we add it to the store
            }else{
                // place the assistanceType in the store
                assistanceStore.setAssistanceType(assistanceType === AssistanceType.DIAGNOSTIC ? AssistanceType.DIAGNOSTIC : AssistanceType.TECHNICAL);
            }
        }
    }

    const router = new VueRouter({
        routes,
        scrollBehavior(to, from, savedPosistion) {
            return {x:0, y:0};
        }
    });

    @Component({
        router,
        components: {
            spinner: SpinnerComponent,
            alert: AlertComponent
        }
    })
    class AssistancePage extends BrandtVue {
        public $t: any;
        public links: { string: string };
    }

    const assistancePage = new AssistancePage({ el: ".AssistancePage" });
}
