


































import Vue from "vue";
import Component from "vue-class-component";
import Address from "./address";
import { AddressType } from "./address-type";
import AlertComponent from "../common/ui/alert.component.vue";
import { ROUTES } from "./account.page";
import Page from "../common/models/page";
import accountService from "./account.service";
import _ from "lodash";

@Component({
  props: {
    context: Object,
  },
  components: {
    alert: AlertComponent
  }
})
export default class AddressListComponent extends Vue {
  public AddressType = AddressType;
  public ErrorCode = {
    Server: "server"
  };
  public ROUTES = ROUTES;
  public addresses: Address[] = [];
  public errorCode: string = null;
  public context: any;

  public get billingAddress(): Address {
    return this.findAddressForType(AddressType.Billing);
  }

  public get shippingAddress(): Address {
    return this.findAddressForType(AddressType.Shipping);
  }

  public created() {
    this.context.isLoading = true;
    (async () => {
      try {
        this.addresses = await accountService.getAddresses();
      } catch (error) {
        this.errorCode = this.ErrorCode.Server;
      }
      this.context.isLoading = false;
    })();
  }

  public getEditAddressRoute(type: AddressType, address?: Address) {
    let queryParameter: string = (type == AddressType.Billing) ? "billing" : "shipping";
    let id = (address && address.id) ? `/${address.id}` : '';
    return `${ROUTES.ADDRESSES_EDIT_ADDRESS}${id}?type=${queryParameter}`;
  }

  public getFormattedAddress(address: Address) {
    const companyName = (!address.companyName) ? '' : address.companyName;
    const line2 = (!address.line2) ? '' : address.line2;
    return `${companyName} <br>
      ${address.firstName} ${address.lastName} <br>
      ${address.line1} ${line2} <br>
      ${address.zipCode} ${address.city} ${address.countryCode}`;
  }

  private findAddressForType(type: AddressType) {
    const address: Address = _.find(this.addresses, (address) => address.type == type);

    return address || null;
  }

}
