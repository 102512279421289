































































import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import AlertComponent from "../ui/alert.component.vue";
import Address from "../../account/address";
import { AlertType } from "../ui/alert-type";
import { DqeStatus } from "./dqe.service";
import _ from "lodash";
import { DqeRnvpResult } from "./dqe.service";

@Component({
  props: {
    result: Object
  },
  components: {
    alert: AlertComponent
  }
})
export default class DqeRnvpComponent extends Vue {
  DqeStatus = DqeStatus;
  result: DqeRnvpResult;

  get alertType(): AlertType {
    switch (this.result.status) {
      case DqeStatus.Success:
        return AlertType.Success;
      case DqeStatus.Warning:
        return AlertType.Warning;
      default:
        return AlertType.Error;
    }
  }

  get filteredCorrections() {
    const result: any = {};
    _.forEach(this.result.corrections, (value, key) => {
      if (!!value) {
        result[key] = value;
      }
    });

    return result;
  }

  applyCorrections() {
    this.$emit("apply-corrections", this.result.corrections);
  }
}
