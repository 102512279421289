











import Vue from "vue";
import Component from "vue-class-component";
import AlertComponent from "../common/ui/alert.component.vue";

@Component({
  components: {
    alert: AlertComponent,
  },
  props: {
    account: Object,
    context: Object,
  },
})
export default class AddressesComponent extends Vue {
  public addressUpdated = false;
}
