import * as _ from "lodash";
import { Carousel, Slide } from "vue-carousel";
import Component, { mixins } from "vue-class-component";
import BrandtVue from "../common/brandt-vue";
import TutorialCarouselMixin from "../common/tutorial-carousel.mixin";
import "./counsel-carousel.scss";
import "./end-product-landing.page.scss";
import "./intervention-request.scss";
import "./assistance-request.scss";
import "./end-product-category-list.mixin.scss";
import PartFinderMixin from "./part-finder.mixin";
import EndProductCategoryListMixin from "./end-product-category-list.mixin";
import NetReviewsSiteReviews from "../common/net-reviews/net-reviews-site-reviews.component.vue";
import RedirectionAlertMixin from "./redirection-alert/redirection-alert.mixin";

if (document.querySelector(".EndProductLandingPage")) {

  @Component({
    components: {
      slide: Slide,
      carousel: Carousel,
      'net-reviews-site-reviews': NetReviewsSiteReviews
    }
  })
  class EndProductLandingPage extends mixins(BrandtVue, PartFinderMixin, RedirectionAlertMixin,
    TutorialCarouselMixin, EndProductCategoryListMixin) {
  }

  const endProductLandingPage = new EndProductLandingPage({ el: ".EndProductLandingPage" });

}
