import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class TutorialCarouselMixin extends Vue {

  public carouselNumberPerPage = 4;

  public created() {
    this.updateCarouselNumberPerPage();
  }

  public mounted() {
    window.addEventListener("resize", () => this.updateCarouselNumberPerPage());
  }

  private updateCarouselNumberPerPage() {
    const clientWidth = window.document.documentElement.clientWidth;
    if (clientWidth > 960) {
      this.carouselNumberPerPage = 4;
    } else if (clientWidth > 768) {
      this.carouselNumberPerPage = 2;
    } else {
      this.carouselNumberPerPage = 1;
    }
  }

}
