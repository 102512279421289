





























































import Vue from "vue";
import Component from "vue-class-component";
import VueRouter, {Route} from "vue-router";
import assistanceStore from "./assistance.store";
import {Timeslot} from "../../common/models/timeslot.model";
import UtilsService from "../../common/services/utils.service";
import {ROUTES} from "./assistance.page";
import CartService from "../../cart/cart.service";
import SpinnerComponent from "../../common/ui/spinner.component.vue";
import AlertComponent from "../../common/ui/alert.component.vue";
import {AssistanceType} from "./assistance-type.enum";

declare var diagnosticPrice: string;
declare var repairPrice: string;

declare var cartUrl: string;

@Component({
    props: {
        product: Object
    },
    components: {
        spinner: SpinnerComponent,
        alert: AlertComponent,
    }
})
export default class AssistanceAddToCartComponent extends Vue {
    public $router: VueRouter;
    public $route: Route;

    private selectedTimeslot: Timeslot = null;
    private submitting = false;
    private error: any | null = null;
    public reference: string;

    private price: string;

    created() {
        this.reference = assistanceStore.endProduct.reference;
        this.selectedTimeslot = assistanceStore.state.selectedTimeslot;

        if (assistanceStore.state.assistanceType === AssistanceType.DIAGNOSTIC) {
            this.price = diagnosticPrice;
        } else {
            this.price = repairPrice;
        }

        if (!this.selectedTimeslot) {
            this.cancel();
        }
    }

    formatTimeslot(timeslot: Timeslot): string {
        return UtilsService.formatTimeslotDateTime(timeslot);
    }

    cancel() {
        this.$router.push({ path: ROUTES.TIMESLOT, query: this.$route.query });
    }

    async addToCart() {
        this.submitting = true;
        this.error = null;

        var assistanceProduct = {
            timeslot: UtilsService.formatTimeslotZoneDateTime(assistanceStore.state.selectedTimeslot),
            assistanceReference: assistanceStore.state.assistanceType,
            endProductReference: assistanceStore.state.endProduct.reference
        };

        try {
            await CartService.insertAssistanceProduct(assistanceProduct);
            window.location.href = cartUrl;
        } catch(e){
            this.error = e;
        } finally {
            this.submitting = false;
        }
    }
}
