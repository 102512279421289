import axios, { AxiosPromise } from "axios";

export class ApiClient {
           public async get<T>(path: string, parameters: any = {}, version = "1.0.0"): Promise<T> {
               return this.unwrapResponse(axios.get(
                       this.getApiPath(path, version),
                       { params: parameters }
                   ));
           }

           public async post<T, D>(path: string, data: D, parameters: any = {}, version = "1.0.0"): Promise<T> {
               return this.unwrapResponse(axios.post(
                       this.getApiPath(path, version),
                       data,
                       {
                           params: parameters
                       }
                   ));
           }

           public async put<T, D>(path: string, data: D, parameters: any = {}, version = "1.0.0"): Promise<T> {
               return this.unwrapResponse(axios.put(
                       this.getApiPath(path, version),
                       data,
                       {
                           params: parameters
                       }
                   ));
           }

           public async patch<T, D>(path: string, data: D, parameters: any = {}, version = "1.0.0"): Promise<T> {
               return this.unwrapResponse(axios.patch(
                       this.getApiPath(path, version),
                       data,
                       {
                           params: parameters
                       }
                   ));
           }

           public async delete<T>(path: string, parameters: any = {}, version = "1.0.0"): Promise<T> {
               return this.unwrapResponse(axios.delete(
                       this.getApiPath(path, version),
                       {
                           params: parameters
                       }
                   ));
           }

           public getApiPath(path: string, version: string) {
               return `${apiUrl}/api/${version}${path}`;
           }

           private async unwrapResponse<T>(promise: AxiosPromise<T>): Promise<T> {
               try {
                   return (await promise).data as T;
               } catch (error) {
                   throw error;
               }
           }
       }

export default new ApiClient();
