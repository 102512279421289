





















import Component, { mixins } from "vue-class-component";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import Alert from "../ui/alert";
import AlertComponent from "../ui/alert.component.vue";
import { AlertType } from "../ui/alert-type"
import AUTHENTICATION_CONSTANTS from "./authentication.constants";
import authenticationService from "./authentication.service";

@Component({
  template: "#common-authentication-forgot-password-template",
  props: {
    action: String,
  },
  components: {
    alert: AlertComponent,
  },
  validations: {
    email: {
      required,
      email,
    },
  },
} as any)
export default class ForgotPasswordComponent extends mixins(validationMixin) {
  public $t: any;
  public email: string = null;
  public ACTION = AUTHENTICATION_CONSTANTS.ACTION;
  public alert: Alert = null;

  public stopPropagation($event: Event) {
    $event.stopPropagation();
  }

  public changeAction(action: string) {
    this.$emit("change-action", action);
  }

  public async resetPassword() {
    try {
      await authenticationService.resetPassword({ email: this.email });
    } catch (error) {}

    this.$emit("show-alert-requested", { type: AlertType.Success, message: this.$t("forgot-password.response-alert") });
  }

}
