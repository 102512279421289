



























































































import Vue from "vue";
import Component from "vue-class-component";
import { Review } from "./review";

@Component({
  props: {
    review: Object,
  },
  components: {},
})
export default class NetReviewsReview extends Vue {
  $t: any;
  review: Review;
  availableRates = [1,2,3,4,5];

  get reviewDate() {
    return `${this.$t('common.net-reviews.date-the')} ${this.review.date.toLocaleDateString()} ${this.$t('common.net-reviews.date-at')} ${this.review.date.toLocaleTimeString()}`;
  }
}
