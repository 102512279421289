


























































































































import Vue from "vue";
import Component from "vue-class-component";
import OrderPersonalDataRegisterComponent from "./order-personal-data-register.component.vue";
import OrderPersonalDataLoginComponent from "./order-personal-data-login.component.vue";
import { Account } from "../common/authentication/account";
import authenticationService from "../common/authentication/authentication.service";
import i18nService from "../common/i18n.service";
import VueRouter from "vue-router";

const i18n = i18nService.getI18n();

@Component({
  components: {
    "order-personal-data-login": OrderPersonalDataLoginComponent,
    "order-personal-data-register": OrderPersonalDataRegisterComponent
  }
})
export default class OrderPersonalDataComponent extends Vue {

  public $router: VueRouter;

}
