import axios from "axios";
import * as jwt_decode from "jwt-decode";
import Address from "../../account/address";
import cartService from "../../cart/cart.service";
import apiClient from "../services/api.client";
import cookieService from "../services/cookie.service";
import {Account} from "./account";
import "./authentication.config";
import AUTHENTICATION_CONSTANTS from "./authentication.constants";
import {Credentials} from "./credentials";
import {Profile} from "./profile";
import {ResetPasswordData} from "./reset-password-data";
import gtmService from "../google-tag-manager/gtm.service";

declare var locale: string;

export class AuthenticationService {

    constructor() {
        if (this.isAuthenticated()) {
            this.updateDataLayer(jwt_decode(this.getToken()).sub);
        }
    }

    public async login(credentials: Credentials) {
        const cartId = cartService.getCartId();
        if (cartId) {
            credentials.cartId = cartId;
        }
        const response = await apiClient.post<any, Credentials>("/login", credentials);

        const token = response.token;
        const decodedToken = jwt_decode(token);
        const ttl = decodedToken.exp - decodedToken.iat;
        cookieService.setCookie(AUTHENTICATION_CONSTANTS.ACCESS_TOKEN_KEY, token, ttl);
        this.updateDataLayer(decodedToken.sub);
        return;
    }

    public logout() {
        cookieService.setCookie(AUTHENTICATION_CONSTANTS.ACCESS_TOKEN_KEY, null, 0);
    }

    public async register(account: any) {
        account.locale = locale;
        const cartId = cartService.getCartId();
        if (cartId) {
            account.cartId = cartId;
        }
        return apiClient.post("/register", account);
    }

    public async resetPassword(resetPasswordData: ResetPasswordData) {
        return apiClient.post<void, ResetPasswordData>("/reset-password", resetPasswordData);
    }

    public executeResetPassword(token: string, newPassword: string): any {
        return apiClient.post<void, any>("/reset-password/execute", {token, newPassword});
    }

    public isAuthenticated() {
        return !!cookieService.getCookie(AUTHENTICATION_CONSTANTS.ACCESS_TOKEN_KEY);
    }

    public getToken() {
        return cookieService.getCookie(AUTHENTICATION_CONSTANTS.ACCESS_TOKEN_KEY) || null;
    }

    public async registerFullInfo(account: Account) {
        return axios.post(apiUrl + "/api/1.0.0/register/full", account);
    }

    public async chargeFullInfoRegistration() {
        return axios.get(apiUrl + "/api/1.0.0/register/full");
    }

    public async updateProfile(profile: Profile) {
        return axios.put(apiUrl + "/api/1.0.0/register/profile", profile);
    }

    public async getAddressesById(addressId: string) {
        return axios.get(apiUrl + "/api/1.0.0/register/addresses/" + addressId);
    }

    public async updateAddress(address: Address) {
        if (address.id) {
            return axios.put(apiUrl + "/api/1.0.0/register/addresses/" + address.id, address);
        } else {
            return axios.post(apiUrl + "/api/1.0.0/register/addresses", address);
        }
    }

    private updateDataLayer(email: string) {
        if (email) {
            gtmService.addVariable("email", email);
        }
    }
}

export default new AuthenticationService();
