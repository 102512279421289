




































import Vue from "vue";
import Component from "vue-class-component";
import FieldComponent from "./field.component.vue";

interface InputDateValue {
  day: number;
  month: number;
  year: number;
}

@Component({
  components: {
    field: FieldComponent
  },
  props: {
    id: String,
    hasError: Boolean,
    errorMessage: String,
    required: Boolean,
    label: String,
    placeholder: String,
    value: Date,
    options: Array
  },
  data() {
    const date: Date = (this as FieldComponent).value;
    let value: InputDateValue;
    if (date) {
      value = {
        day: date.getUTCDate(),
        month: date.getUTCMonth(),
        year: date.getUTCFullYear()
      };
    } else {
      value = {
        day: null,
        month: null,
        year: null
      };
    }
    return {
      inputValue: value
    };
  },
  watch: {
    inputValue: {
      handler: function(value) {
        this.sendInputIfComplete(value);
      },
      deep: true
    }
  }
})
export default class BrithDateSelectorComponent extends Vue {
  public inputValue: InputDateValue;
  public label: string;
  public $t: any;
  public required: boolean;

  public months: any[] = [];
  public currentYear = new Date().getUTCFullYear();

  public created() {
    this.months = [
      { value: 1, label: this.$t("common.birthdate-selector.months.january") },
      { value: 2, label: this.$t("common.birthdate-selector.months.february") },
      { value: 3, label: this.$t("common.birthdate-selector.months.march") },
      { value: 4, label: this.$t("common.birthdate-selector.months.april") },
      { value: 5, label: this.$t("common.birthdate-selector.months.may") },
      { value: 6, label: this.$t("common.birthdate-selector.months.june") },
      { value: 7, label: this.$t("common.birthdate-selector.months.july") },
      { value: 8, label: this.$t("common.birthdate-selector.months.august") },
      { value: 9,label: this.$t("common.birthdate-selector.months.september") },
      { value: 10, label: this.$t("common.birthdate-selector.months.october") },
      { value: 11, label: this.$t("common.birthdate-selector.months.november") },
      { value: 12, label: this.$t("common.birthdate-selector.months.december") }
    ];
  }

  public sendInputIfComplete(value: InputDateValue) {
    if (value.day && value.month && value.year) {
      const date = new Date();
      date.setUTCDate(value.day);
      date.setUTCMonth(value.month - 1);
      date.setUTCFullYear(value.year);

      if (
        date.getUTCDate() != value.day ||
        date.getUTCMonth() != value.month - 1 ||
        date.getUTCFullYear() != value.year
      ) {
        this.inputValue.day = date.getUTCDate();
        this.inputValue.month = date.getUTCMonth();
        this.inputValue.year = date.getUTCFullYear();
      }

      this.$emit("input", date);
    }
  }

  public getDaysInMonth(year: number, month: number) {
    return new Date(year, month + 1, 0).getDate();
  }

  public formatLabel() {
    if (!this.label) {
      return "";
    }
    return this.label + (this.required ? "* :" : ":");
  }
}
