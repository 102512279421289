import Component from "vue-class-component";
import BrandtVue from "../common/brandt-vue";

if (document.querySelector(".ArticleDetailPage")) {

  @Component({
  })
  class ArticleDetailPage extends BrandtVue {

  }
  const articleDetailPage = new ArticleDetailPage({ el: ".ArticleDetailPage" });

}
