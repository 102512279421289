














































































































































import Vue from "vue";
import Component from "vue-class-component";
import ModalComponent from "./modal.component.vue";

@Component({
    name: "confirmation-modal",
    props: {
        title: String,
        body: String,
        confirmButtonText: String,
        cancelButtonText: String
    },
    components: {
        modal: ModalComponent
    }
})
export default class ConfirmationModal extends Vue {

    constructor() {
        super();
    }

}
