import { library } from '@fortawesome/fontawesome-svg-core';
import {faDownload, faEdit, faInfoCircle, faTrash} from '@fortawesome/free-solid-svg-icons';

export class FontAwesomeService {
    // the purpose of init is to add every necessary icon to the const library
    public init(){
        library.add(faInfoCircle);
        library.add(faDownload);
        library.add(faTrash);
        library.add(faEdit);
    }
}
export default new FontAwesomeService();
