export enum CountryCode {
  BR = "BR",
  DE = "DE",
  AD = "AD",
  AT = "AT",
  BE = "BE",
  BG = "BG",
  HR = "HR",
  DK = "DK",
  ES = "ES",
  EE = "EE",
  FI = "FI",
  FR = "FR",
  GR = "GR",
  GP = "GP",
  HU = "HU",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  LV = "LV",
  LI = "LI",
  LT = "LT",
  LU = "LU",
  MQ = "MQ",
  MC = "MC",
  NO = "NO",
  NL = "NL",
  PL = "PL",
  PT = "PT",
  GB = "GB",
  CZ = "CZ",
  IE = "IE",
  SK = "SK",
  SI = "SI",
  CH = "CH",
  SE = "SE",
  TR = "TR",
  VA = "VA",
  JP = "JP",
  US = "US",
  CA = "CA",
}
