

























































































import Vue from "vue";
import Component from "vue-class-component";

@Component({
    name: "modal",
})
export default class ModalComponent extends Vue {

}
