








import Vue from "vue";
import Component from "vue-class-component";
import FieldComponent from "../common/ui/field.component.vue";
import i18nService from "../common/i18n.service";
import genders from "./genders.json";

@Component({
  props: {
    id: String,
    required: Boolean,
    value: String,
  },
  components: {
    field: FieldComponent
  },
  data() {
    return {
      inputValue: (this as GenderSelectorComponent).value
    }
  },
  watch: {
    inputValue(value) {
      this.$emit("input", value);
    }
  }
})
export default class GenderSelectorComponent extends Vue {
  public $t: any;
  public value: string;

  private _genders: any[] = [];

  public created() {
    this._genders = genders;
  }

  public get genders() {
    return this._genders.map(gender => {
      gender.label = this.$t(
        "account.gender-selector." + gender.code.toLowerCase()
      ) as string;
      return gender;
    });
  }
}
