






































































import _ from "lodash";
import Component, { mixins } from "vue-class-component";
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import accountService from "../../account/account.service";

@Component({
    validations: {
        newsletterEmail: {
            email,
            required
        }
    }
} as any)
export default class NewsletterComponent extends mixins(validationMixin) {
    public $v: any;
    invalid: boolean = false;
    disabled: boolean = false;

    error: string = null;
    success: string = null;

    public newsletterEmail: string = null;

    data() {
        return {
            invalid: false
        };
    }

    validate() {
        this.$v.$touch();
        this.invalid =
            this.$v.newsletterEmail.$invalid && this.$v.newsletterEmail.$dirty;
    }

    async addToNewsletter() {
        this.error = null;
        this.success = null;

        this.validate();
        if (!this.$v.$invalid) {
            this.disabled = true;
            try {
                await accountService.subscribeNewsletter(
                    this.$v.newsletterEmail.$model
                );
                this.success = "Un email de vérification vous a été envoyé.";
            } catch {
                this.error = "Une erreur s'est produite.";
                this.disabled = false;
            }
        }
    }
}
