import axios from "axios";
import cookieService from "../services/cookie.service";
import AUTHENTICATION_CONSTANTS from "./authentication.constants";

const exceptions = [
  "/api/1.0.0/login",
  "/api/1.0.0/register",
];

// var accessToken = localStorage.getItem(AUTHENTICATION_CONSTANTS.ACCESS_TOKEN_KEY);
// var accessToken = cookieService.getCookie(AUTHENTICATION_CONSTANTS.ACCESS_TOKEN_KEY);
// var noAuth = exceptions.filter(function(e) { config.url.indexOf(e) >= 0 }).length == 0;
// if (!!accessToken && noAuth) {
//   axios.defaults.headers.common['Authorization'] = accessToken;
// }

axios.interceptors.request.use((config) => {
  const accessToken = cookieService.getCookie(AUTHENTICATION_CONSTANTS.ACCESS_TOKEN_KEY);

  const noAuth = exceptions.filter((e) => {
    return config.url && config.url.indexOf(e) === 0;
  }).length === 0;

  if (!!accessToken && noAuth) {
    config.headers.Authorization = accessToken;
  }
  return config;
});
