
declare var dataLayer: any;

/**
 * Google Tag Manager. Interactions with Data Layer.
 */
export class GtmService {

    constructor() {}

    public addVariable(key: string, value: any) {
        dataLayer.push({[key]: value});
    }
}

export default new GtmService();
