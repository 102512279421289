






















































































































































import Component from "vue-class-component";
import Vue from "vue";

@Component({
  props: {
    id: String,
    hasError: Boolean,
    errorMessage: String,
    required: Boolean,
    label: String,
    placeholder: String,
    value: [String, Number, Boolean, Object, Date],
    options: Array,
    pattern: String,
    disabled: Boolean,
    autocomplete: {
      type: String,
      default: "on"
    },
    type: {
      type: String,
      default: "text"
    },
    displayAsterisk: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      inputValue: (this as FieldComponent).value
    };
  },
  watch: {
    value(value) {
      if (this.$refs.input) {
        this.$refs.input.value = value;
      }
    },
    inputValue(value) {
      this.$emit("input", value);
      this.$emit("change", value);
    }
  }
})
export default class FieldComponent extends Vue {
  public value: any;
  public required: boolean;
  public label: string;
  public displayAsterisk: boolean;

  public formatLabel() {
    if (!this.label) {
      return "";
    }
    return this.label + (this.required && this.displayAsterisk ? " * :" : " :");
  }
}
