import Component, { mixins } from "vue-class-component";
import BrandtVue from "../common/brandt-vue";
import ProductAddModal from "../common/ui/product-add-modal.component.vue";
import AccessoryCategoryMenuMixin from "./accessory-category-menu.mixin";

if (document.querySelector(".AccessoryListPage")) {

  @Component({
    components: {
      "product-add-modal": ProductAddModal,
    },
  })
  class AccessoryListPage extends mixins(BrandtVue, AccessoryCategoryMenuMixin) {
  }

  const accessoryListPage = new AccessoryListPage({ el: ".AccessoryListPage" });

}
