










import Vue from "vue";
import Component from "vue-class-component";

@Component({
  props: {
    account: Object,
    order: Object,
    context: Object,
  },
})
export default class AssistancesComponent extends Vue {
}
