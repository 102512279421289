export class CookieService {

    public setCookie(name: string, value: string, ttl: number) {
        const now = new Date();
        now.setTime(now.getTime() + ttl * 1000);
        const expires = "expires=" + now.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }

    public getCookie(name: string) {
        name = name + "=";
        const cookieStrings = document.cookie.split(";");
        for (let i = 0; i < cookieStrings.length; i++) {
            let c = cookieStrings[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

}

export default new CookieService();
