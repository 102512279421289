import apiClient from "../common/services/api.client";
import AlertProduct from "./alert-product";

export class AlertProductService {

    public addAlertOnProduct(alertProduct: AlertProduct) {
        return apiClient.post("/product-alerts", alertProduct);
    }

}

export default new AlertProductService();