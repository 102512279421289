import _ from "lodash";
import accountService from "../../account/account.service";
import Address from "../../account/address";
import {AddressType} from "../../account/address-type";
import {Cart} from "../../cart/cart";
import cartService from "../../cart/cart.service";
import orderService from "../order.service";
import Country from "./country";
import CreateOrder from "./create-order";

declare var locale: string;

export interface OrderState {
    shippingAndBillingAddressDifferent: boolean;
    preferencesFormInvalid: boolean;
    isLoading: boolean;
    termsAndConditionsAccepted: boolean;
    addressesLoaded: boolean;
    cart: Cart;
    order: CreateOrder;
    paymentMethods: any[];
    countries: Country[];
}

export class OrderStore {
    private _state: OrderState = {
        shippingAndBillingAddressDifferent: false,
        preferencesFormInvalid: false,
        isLoading: false,
        termsAndConditionsAccepted: false,
        addressesLoaded: false,
        cart: {
            items: null,
            countryCode: null,
            promoCode: null,
            itemsAmount: 0,
            discountAmount: 0
        },
        order: {
            cartId: null,
            locale,
            phoneNumber: null,
            instructions: null,
            shippingAddress: {
                line1: null,
                line2: null,
                city: null,
                default: true,
                zipCode: null,
                countryCode: "FR",
                firstName: null,
                lastName: null,
                companyName: null,
                type: AddressType.Shipping
            },
            invoicingAddress: {
                line1: null,
                line2: null,
                city: null,
                default: true,
                zipCode: null,
                countryCode: "FR",
                firstName: null,
                lastName: null,
                companyName: null,
                type: AddressType.Billing
            },
            lines: null,
            customer: null,
            marketingOptin: false
        },
        paymentMethods: [],
        countries: []
    };

    public get state() {
        return this._state;
    }

    public setTermAndConditionsAccepted(accepted: boolean) {
        this._state.termsAndConditionsAccepted = accepted;
    }

    public setPreferencesFormInvalid(invalid: boolean) {
        this._state.preferencesFormInvalid = invalid;
    }

    public setAddresses(shippingAddress: Address, invoicingAddress: Address) {
        this._state.order.shippingAddress = shippingAddress;
        this._state.order.invoicingAddress = invoicingAddress;
    }

    public setShippingAndBillingAddressDifferent(value: boolean) {
        this._state.shippingAndBillingAddressDifferent = value;
    }

    public setMarketingOptin(value: boolean){
        this._state.order.marketingOptin = value;
    }

    public async getCountries() {
        this._state.isLoading = true;
        try {
            this._state.countries = await orderService.getCountries();
        } catch (error) {
            throw error;
        } finally {
            this._state.isLoading = false;
        }
    }

    public async createOrder(
        invoicingAddress: Address,
        shippingAddress?: Address,
        instructions?: string
    ) {
        if (!shippingAddress) {
            this._state.order.shippingAddress = _.cloneDeep(invoicingAddress);
            this._state.order.shippingAddress.type = AddressType.Shipping;
        } else {
            this._state.order.shippingAddress = shippingAddress;
        }

        this._state.order.invoicingAddress = invoicingAddress;
        this._state.order.phoneNumber = invoicingAddress.phoneNumber;

        try {
            // Create order
            this._state.isLoading = true;
            this._state.order.cartId = this._state.cart.id;
            this._state.order.instructions = instructions;
            const { paymentMethods, order } = (await orderService.saveOrder(
                this._state.order
            )) as any;
            const transformedMethods = _.sortBy(
                paymentMethods,
                paymentMethod => paymentMethod.type
            );
            transformedMethods.splice(1, 0, { type: "separator" });
            this._state.paymentMethods = transformedMethods;
            this._state.order = order;
            this._state.order.locale = locale;
            this._state.order.invoicingAddress.phoneNumber = this._state.order.phoneNumber;
        } catch (error) {
            throw error;
        } finally {
            this._state.isLoading = false;
        }
    }

    public async getCart() {
        this._state.isLoading = true;
        try {
            this._state.cart = await cartService.getCart();
        } catch (error) {
            throw error;
        } finally {
            this._state.isLoading = false;
        }
    }

    public async setAccountInfos(){
        if (this._state.order.invoicingAddress.lastName != null) {
            return;
        }
        try{
            const account = await accountService.getAccount();
            if (account.lastName != null && account.firstName != null){
                this._state.order.invoicingAddress.lastName = account.lastName;
                this._state.order.invoicingAddress.firstName = account.firstName;
            }
        } catch(error){
            throw error;
        }
    }

    public async getAddresses() {
        if (this._state.addressesLoaded) {
            return;
        }
        this._state.isLoading = true;
        try {
            const addresses = await accountService.getAddresses();
            this._state.order.shippingAddress = this.findAddressForType(
                addresses,
                AddressType.Shipping
            );
            this._state.order.invoicingAddress = this.findAddressForType(
                addresses,
                AddressType.Billing
            );
            this._state.order.phoneNumber = this._state.order.invoicingAddress.phoneNumber;
            this._state.addressesLoaded = true;
        } catch (error) {
            throw error;
        } finally {
            this._state.isLoading = false;
        }
    }

    private findAddressForType(addresses: Address[], type: AddressType) {
        const address: Address = _.find(
            addresses,
            a => a.type === type && a.default === true
        );

        return (
            address || {
                line1: null,
                line2: null,
                city: null,
                default: true,
                zipCode: null,
                countryCode: "FR",
                firstName: null,
                lastName: null,
                companyName: null,
                type
            }
        );
    }
}

export default new OrderStore();
