

















































import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import AlertComponent from "../common/ui/alert.component.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import FieldComponent from "../common/ui/field.component.vue";
import accountService from "./account.service";

@Component({
  components: {
    alert: AlertComponent,
    field: FieldComponent
  },
  props: {
    account: Object,
    context: Object
  },
  validations: {
    oldPassword: {
      required
    },
    newPassword: {
      required
    },
    newPasswordRepeat: {
      required,
      differentThanNewPassword: function(value: string) {
        return this.newPassword === value;
      }
    }
  }
} as any)
export default class ChangePasswordComponent extends mixins(validationMixin) {
  public ErrorCode = {
    Server: "server"
  };
  public oldPassword: string = null;
  public newPassword: string = null;
  public newPasswordRepeat: string = null;
  public submitting = false;
  public submitSuccess = false;
  public submitErrorCode: string = null;
  public context: any;

  public async updatePassword() {
    this.submitting = true;
    this.submitErrorCode = null;
    try {
      await accountService.updatePassword({ newPassword: this.newPassword, oldPassword: this.oldPassword });
      this.submitSuccess = true;
    } catch(error) {
      this.submitErrorCode = this.ErrorCode.Server;
    }
    this.submitting = false;
  }

  public getErrorKey(errors: any) {
    if (!errors.differentThanNewPassword) {
      return 'account.change-password.different-password-error';
    }
    return 'common.error.field-required';
  }
}
