import * as _ from "lodash";
import IAsyncCommand from "../common/command";
import { Product } from "../common/models/product";
import { Cart } from "./cart";
import cartService from "./cart.service";
import { CartItem } from "./cart-item";

export default class DeleteCartItemCommand implements IAsyncCommand {
                   private _isDone = false;

                   get isDone() {
                       return this._isDone;
                   }

                   get item() {
                       return this._item;
                   }

                   get cart() {
                       return this._cart;
                   }

                   constructor(private _item: CartItem, private _cart?: Cart) {}

                   public async do() {
                       if (this.isDone) {
                           return;
                       }
                       this._cart = await cartService.removeItem(this._item.id);
                       this._isDone = true;
                       return this._cart;
                   }

                   public async undo() {
                       if (!this.isDone) {
                           return;
                       }
                       return await cartService.upsertProduct(
                           this._item.productId,
                           this._item.quantity,
                           this._item.displayIndex,
                           this._item.assistanceRdv
                           );
                   }
               }
