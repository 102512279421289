import Vue from "vue";
import Component from "vue-class-component";
import {Product} from "../common/models/product";
import localStorageService from "../common/services/local-storage.service";
import {AlertType} from "../common/ui/alert-type";
import AddCartItemCommand from "./add-cart-item-command";
import {Cart} from "./cart";
import CartSliderComponent from "./cart-slider.component.vue";
import cartService from "./cart.service";
import ProductAddedModalComponent from "./product-added-modal.component.vue";

declare var products: Product[];
declare var orderLink: string;
declare var dataLayer: any;

enum AddedProductNotificationType {
    Modal = "modal",
    Toast = "toast",
}

@Component({
    components: {
        "cart-slider": CartSliderComponent,
        "product-added-modal": ProductAddedModalComponent,
    }
})
export default class CartMixin extends Vue {

    public $t: any;
    public cartSliderOpen = false;
    public addedProduct: Product = null;
    public addedProductModalVisible = false;
    public cartItemCount = 0;

    public created() {
        this.refreshCartCount();
    }

    public async addProductToCart(
        productInfos: any,
        notificationType = AddedProductNotificationType.Modal) {
        const product = productInfos.product;
        const dataLayerProduct = productInfos.dataLayer || productInfos.product.dataLayer;

        if (product) {
            const addCommand = new AddCartItemCommand(product);
            await addCommand.do();
            this.refreshCartCount();

            if (notificationType === AddedProductNotificationType.Modal) {
                this.addedProduct = product;
                this.addedProductModalVisible = true;
                setTimeout(() => {
                    this.addedProductModalVisible = false;
                }, 5000);
            } else {
                this.$emit("notification", {
                    alertType: AlertType.Success,
                    message: this.$t("cart.item-added", { addedItem: product.title }),
                    actionLink: {
                        href: orderLink,
                        title: this.$t("cart.view-cart")
                    }
                });
            }

            if (dataLayerProduct) {
                const dataLayerProductInfos = { ...dataLayerProduct, quantity: 1 };
                dataLayer.push({
                    event: "add_to_cart",
                    ecommerce: {
                        currency:
                            product.price.currencyCode,
                        value: dataLayerProductInfos.price,
                        items: [dataLayerProductInfos],
                    }
                });
            }
        }
    }

    public async refreshIfNeeded(cart: Cart) {
        if (!!(this as any).cart) {
            (this as any).cart = cart;
        }
    }

    public async refreshCartCount() {
        const cartItemCount = localStorageService.getItem("cartItemCount");
        this.cartItemCount = cartItemCount;
        this.cartItemCount = await cartService.getCount();
        localStorageService.setItem("cartItemCount", this.cartItemCount);
    }

}
