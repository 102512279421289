








































































































































































































































































































































import Component, { mixins } from "vue-class-component";
import VueRouter from "vue-router";
import PartFinderMixin from "../../end-product/part-finder.mixin";
import { ROUTES } from "./assistance.page";
import _ from "lodash";
import assistanceStore from "./assistance.store";
import EndProduct from "../../end-product/end-product";

declare var staticUrl: string;
declare var howToFindReferenceLink: string;
declare var netReviews: any;

@Component({})
export default class AssistanceChoiceComponent extends mixins(PartFinderMixin) {
    public $router: VueRouter;
    public howToFindReferenceLink = howToFindReferenceLink;
    public staticUrl = staticUrl;

    public netReviews = netReviews;

    //override the partFinderFollowLink to save the found product in the store
    //then redirect to the step two of the tunnel
    public partFinderFollowLink() {
        const currentlySelected = _.find(
            this.results,
            result => result.selected
        );

        var endProduct: EndProduct = {
            reference: currentlySelected.reference,
            commercialReference: currentlySelected.commercialReference,
            technicalReference: currentlySelected.technicalReference,
            link: currentlySelected.link,
            brand: currentlySelected.brand,
            label: currentlySelected.label
        };

        assistanceStore.setEndProduct(endProduct);
        this.$router.push({
            path: ROUTES.SELECT_VISIO,
            query: { reference: assistanceStore.endProduct.reference }
        });
    }
}
