







































































































































































































































































































import Component, {mixins} from "vue-class-component";
import SliderComponent from "../common/ui/slider.component.vue";
import CartDetailMixin from "./cart-detail.mixin";
import {CartItem} from "./cart-item";

declare var staticUrl: string;

@Component({
  name: "cart-slider",
  components: { slider: SliderComponent },
  props: {
    cartLink: String
  }
})
export default class CartSliderComponent extends mixins(CartDetailMixin) {

    public noImageSrc = staticUrl + "/pictures/no-image.png";

    public isAvailable(item: CartItem): boolean {
        return item.product.status.name == 'AVAILABLE';
    }

    public canChangeQuantity(item: any): boolean {
      return item.product.type !== 'ASSISTANCE';
    }
}
