




























































































import Vue from "vue";
import Component from "vue-class-component";

declare var staticUrl: string;

@Component({
    props: {
        data: Object,
    }
})
export default class AssistanceVisioOrTechnicCardComponent extends Vue {

    public staticUrl = staticUrl;

}
