import cartService from "../../cart/cart.service";
import Product from "../../product/product";
import authenticationService from "../authentication/authentication.service";
import Page from "../models/page";
import apiClient from "../services/api.client";
import { CartCrossSellingRequest, AssistanceCrossSellingRequest } from "./cart-cross-selling-request";
import CrossSellingRequest from "./cross-selling-request";
import { CrossSellingRequestType } from "./cross-selling-request-type.enum";

declare var locale: string;

export class CrossSellingService {

  public async getCrossSellingProducts<T extends CrossSellingRequest>(request: T) {
    let endpoint = "/cross-selling";
    if (authenticationService.isAuthenticated()) {
      endpoint += "/me";
    }

    return apiClient.post<Page<Product>, CrossSellingRequest>(endpoint, request);
  }

  public createCartCrossSellingRequest(partialRequest: Partial<CartCrossSellingRequest>): CartCrossSellingRequest {
    return new CartCrossSellingRequest(Object.assign({}, partialRequest, {
      locale,
      cartId: cartService.getCartId()
    }));
  }


  public createCartCrossSellingRequestByAssistance(partialRequest: Partial<CartCrossSellingRequest>): CartCrossSellingRequest {
    return new CartCrossSellingRequest({ ...partialRequest, locale });
  }

  public createCartCrossSellingRequestByAccountAssistance(partialRequest: Partial<AssistanceCrossSellingRequest>, assistanceId: string): AssistanceCrossSellingRequest {
    return new AssistanceCrossSellingRequest({ ...partialRequest, locale, assistanceId });
  }
}

const crossSellingService = new CrossSellingService();
export default crossSellingService;
