














































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Order from "./order.model";
import Address from "./address";
import moment from "moment-timezone";
import { Route } from "vue-router";
import accountService from "./account.service";
import apiClient from "../common/services/api.client";
import authenticationService from "../common/authentication/authentication.service";
import OrderTrackingComponent from "./order-tracking.component.vue";

import OrderReturnFormModal from "./order-return-form-modal.component.vue";
import AlertComponent from "../common/ui/alert.component.vue";
import { AlertType } from "../common/ui/alert-type";

declare var locale: string;

@Component({
  props: {
    context: Object,
    account: Object,
  },
  components: {
    "order-return-form-modal": OrderReturnFormModal,
    "order-tracking": OrderTrackingComponent,
    alert: AlertComponent,
  },
})
export default class OrderDetailsComponent extends Vue {
  public context: any;
  public $route: Route;
  public order: Order = null;
  public returnModalOpen = false;
  public AlertType = AlertType;
  public isReturnable = false;

  public created() {
    const orderId = this.$route.params.id;
    this.context.isLoading = true;
    (async () => {
      try {
        this.order = await accountService.getOrder(orderId);
        this.isReturnable = this.order.status === "SENT"
            || this.order.status === "INVOICED";
      } catch {}
      this.context.isLoading = false;
    })();
  }

  public getFormattedAddress(address: Address) {
    const companyName = !address.companyName ? "" : address.companyName;
    const line2 = !address.line2 ? "" : address.line2;
    return `${companyName} <br>
        ${address.firstName} ${address.lastName} <br>
        ${address.line1} ${line2} <br>
        ${address.zipCode} ${address.city} ${address.countryCode}`;
  }

  public formatDate(date: Date) {
    return moment(String(date)).format("DD/MM/YYYY");
  }

  public formatHour(date: Date) {
    return moment(String(date)).format("HH:mm");
  }

  public getBillLink(billId: string) {
    const accessToken = authenticationService.getToken();
    const url = apiClient.getApiPath(
      `/account/orders/${this.order.id}/bills/${billId}?locale=${locale}&access_token=${accessToken}`,
      "1.0.0"
    );
    return url;
  }
}
