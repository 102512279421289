import Component, {mixins} from "vue-class-component";
import BrandtVue from "../common/brandt-vue";
import BeCalledBackMixin from "../common/be-called-back.mixin";
import VueSimpleAccordion from 'vue-simple-accordion';
import Vue from "vue";
import './qualirepar.scss';

if (document.querySelector(".QualireparPage")) {

    Vue.use(VueSimpleAccordion);

    @Component({})
    class QualireparPage extends mixins(BrandtVue, BeCalledBackMixin) {
        initActiveByItem: any = {
            'what-is-qualirepar': false,
            'how-to-benefit': false,
            'applicable-devices': false,
            'offers-by-brand': false,
            'bonus-application': false,
            'bonus-exclusion': false,
        };

        activateTab(id: any) {
            location.hash = `#${id}`;
            location.reload();
        }

        beforeMount() {
            if (location.hash) {
                const id = location.hash.substr(1);
                this.initActiveByItem[id] = true;
            }
        }
    }

    const page = new QualireparPage({el: ".QualireparPage"});
}
