




























































































































































import Component, {mixins} from "vue-class-component";
import {validationMixin} from "vuelidate";
import {maxLength, required} from "vuelidate/lib/validators";
import FieldComponent from "../../common/ui/field.component.vue";
import zipCodeValidator from "../../common/validators/zip-code.validator";
import dqeService, {DqeRnvpResult, DqeStatus} from "../../common/dqe/dqe.service";
import DqeRnvpComponent from "../../common/dqe/dqe-rnvp.component.vue";
import Address from "../../account/address";
import SpinnerComponent from "../../common/ui/spinner.component.vue";
import AlertComponent from "../../common/ui/alert.component.vue";

declare var locale: string;

@Component({
  components: {
    spinner: SpinnerComponent,
    field: FieldComponent,
    "dqe-rnvp": DqeRnvpComponent,
    alert: AlertComponent
  },
  props: {
    withPhoneNumber: Boolean,
    idPrefix: String,
    address: Object,
    countries: Array
  },
  watch: {
    "$v.$invalid": function(value: boolean) {
      this.$emit("is-invalid", value);
    }
  },
  validations: {
    address: {
      phoneNumber: {
        required: function(value: string) {
          if (this.withPhoneNumber) {
            return required(value);
          }
          return true;
        },
        maxLength: maxLength(32)
      },
      line1: {
        required,
        maxLength: maxLength(32)
      },
      line2: {
        maxLength: maxLength(32)
      },
      city: {
        required,
        maxLength: maxLength(32)
      },
      zipCode: {
        required,
        zipCode: function(zipCode: string) {
          return zipCodeValidator.validate(zipCode, this.address.countryCode);
        },
        maxLength: maxLength(32)
      },
      countryCode: {
        required,
        maxLength: maxLength(32)
      },
      firstName: {
        required,
        maxLength: maxLength(32)
      },
      lastName: {
        required,
        maxLength: maxLength(32)
      },
      companyName: {
        maxLength: maxLength(32)
      }
    }
  }
} as any)
export default class OrderPreferenceAddressComponent extends mixins(
  validationMixin
) {
  public $v: any;
  public withPhoneNumber: boolean;
  public dqeRnvpResult: DqeRnvpResult = null;
  public address: Address;
  public verificationPending = false;
  public backListed = false;

  public created(){
      this.verifyAddress();
  }

  public mounted() {
    // this.verifyBlackList();
    this.$emit("is-invalid", this.backListed);
  }

  public getErrorKey(validation: any) {
    if (validation.zipCode === false) {
      return "common.error.field-invalid-zip-code";
    } else if (validation.maxLength === false) {
      return "common.error.field-max-length";
    }
    return "common.error.field-required";
  }

  public async verifyAddress() {
    /*
    this.verifyBlackList();
    if (this.backListed) {
      return;
    }
    */
    if (locale !== 'fr_FR') {
      return;
    }

    if (!this.$v.$invalid) {
      this.verificationPending = true;
      this.$emit("is-invalid", true);
      try {
        this.dqeRnvpResult = await dqeService.checkAddress(this.address);
        if (this.dqeRnvpResult.status === DqeStatus.Warning) {
          this.applyCorrections(this.dqeRnvpResult.corrections);
        }
        this.$emit(
          "is-invalid",
          this.dqeRnvpResult.status !== DqeStatus.Success &&
            this.dqeRnvpResult.status !== DqeStatus.Warning
        );
      } catch (e) {
        this.$emit("is-invalid", false);
      } finally {
        this.verificationPending = false;
      }
    }
  }

  public applyCorrections(corrections: Address) {
    for (let key in corrections) {
      (this.address as any)[key] = (corrections as any)[key];
    }
  }

  private verifyBlackList() {
    const blackListRegexp = new RegExp("^(67|21|25|39|52|68|70|55|57|71|90)");
    if (blackListRegexp.test(this.address.zipCode)) {
      this.$emit("is-invalid", true);
      this.backListed = true;
    } else {
      this.$emit("is-invalid", false);
      this.backListed = false;
    }
  }

  public checkForm(){
    return this.$v.$invalid;
  }
}
