import Vue from "vue";
import { Carousel, Slide } from "vue-carousel";
import Component from "vue-class-component";

@Component({
  components: {
    carousel: Carousel,
    slide: Slide
  }
})
export default class EndProductCategoryListMixin extends Vue {

  public categoryListCarouselNumberPerPage = 8;

  public created() {
    this.updateCategoryListCarouselNumberPerPage();
  }

  public mounted() {
    window.addEventListener("resize", () => this.updateCategoryListCarouselNumberPerPage());
  }

  private updateCategoryListCarouselNumberPerPage() {
    const clientWidth = window.document.documentElement.clientWidth;
    if (clientWidth > 1400) {
      this.categoryListCarouselNumberPerPage = 8;
    } else if (clientWidth > 960) {
      this.categoryListCarouselNumberPerPage = 6;
    } else {
      this.categoryListCarouselNumberPerPage = 2;
    }
  }

}
