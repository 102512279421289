
























import Vue from "vue";
import Component from "vue-class-component";
import AlertComponent from "../common/ui/alert.component.vue";
import GdprConfirmationModalComponent from "./gdpr-confirmation-modal.component.vue";
import accountService from "./account.service";
import _ from "lodash";

@Component({
  props: {
    context: Object
  },
  components: {
    alert: AlertComponent,
    "gdpr-confirmation-modal": GdprConfirmationModalComponent,
  }
})
export default class GdprComponent extends Vue {
  public context: any;
  public requestSuccess = false;
  public serverError = false;
  public badRequestError = false;
  public showGdprConfirmation = false;

  public async submitPersonalDataRemovalRequest() {
    this.showGdprConfirmation = true;
  }

  public async onModalClose(data: any){
    this.showGdprConfirmation = false;
    if(data){
      this.sendRequest();
    }
  }

  private async sendRequest(){
    this.context.isLoading = true;
    this.requestSuccess = false;
    this.serverError = false;
    this.badRequestError = false;
    try {
      await accountService.sendGdprRequest();
      this.requestSuccess = true;
    } catch(error) {
      if(error && error.response && error.response.data 
        && error.response.data.code === 'BAD_REQUEST'){
        this.badRequestError = true;
      } else {
        this.serverError = true;
      }
    } finally {
      this.context.isLoading = false;
    }
  }

}
