


























import Vue from "vue";
import Component from "vue-class-component";
import VueRouter, {Route} from "vue-router";
import {ROUTES} from "./assistance.page";
import {Timeslot} from "../../common/models/timeslot.model";
import assistanceStore from "./assistance.store";
import TimeslotSelectionComponent from "./timeslot-selection.component.vue";
import {AssistanceType} from "./assistance-type.enum";
import {AssistanceSearchType} from "./assistance-search.type.enum";

@Component({
    components:{
        'timeslot-selection': TimeslotSelectionComponent
    },
    props: {
        product: Object
    }
})
export default class AssistanceTimeslotSelectionComponent extends Vue {
    public $router: VueRouter;
    public $route: Route;
    public reference: string;
    public displayTechTitle: boolean = true;
    public assistanceType: string;

    created(){
        this.assistanceType = assistanceStore.assistanceType == AssistanceType.DIAGNOSTIC ? AssistanceSearchType.DIAGNOSTIC : AssistanceSearchType.ASSISTANCE;
        this.displayTechTitle = assistanceStore.assistanceType === AssistanceType.TECHNICAL;
        this.reference = assistanceStore.endProduct.reference;
    }

    public cancel() {
        this.$router.push({ path: ROUTES.CHOICE, query: this.$route.query });
    }

    public confirm(event: Timeslot) {
        var selectedTimeslot = event;
        assistanceStore.setSelectedTimeslot(selectedTimeslot);
        this.$router.push({ path: ROUTES.ADD_TO_CART, query: this.$route.query });
    }
}
