import Component from "vue-class-component";
import Vue from "vue";
import { Product } from "../common/models/product";
import _ from "lodash";
import AlertProductModal from "./alert-product-modal.component.vue";

declare var products: any;

@Component({
    components: {
        "alert-product-modal": AlertProductModal,
    }
})
export default class ProductAlertMixin extends Vue {

    public showAddAlertOnProductModal = false;
    public addAlertOnProductCurrentProduct: Product = null;

    public addAlertOnProduct(product: Product) {
        this.addAlertOnProductCurrentProduct = product;
        this.showAddAlertOnProductModal = true;
    }

}
