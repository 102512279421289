import Component from "vue-class-component";
import BrandtVue from "../common/brandt-vue";

import "../common/aside-cta/aside-with-cta.scss";
import PhotoModalComponent from "../common/ui/photo-modal.component.vue";
import CompatibilityChecker from "../compatibility-check/compatibility-checker.vue";
import NetReviewsProductReviews from "../common/net-reviews/net-reviews-product-reviews.component.vue";
import AsideCtaInterventionComponent from "../common/aside-cta/aside-cta-intervention.component.vue";
import AsideCtaYoutubeComponent from "../common/aside-cta/aside-cta-youtube.component.vue";

if (document.querySelector(".PartDetailPage")) {

  @Component({
    components: {
      "photo-modal": PhotoModalComponent,
      "compatibility-checker": CompatibilityChecker,
      "net-reviews-product-reviews": NetReviewsProductReviews,
      "cta-intervention": AsideCtaInterventionComponent,
      "cta-youtube": AsideCtaYoutubeComponent
    },
  })
  class PartDetailPage extends BrandtVue {

    public activeTab = "description";
    public showModal = false;

  }

  const partDetailPage = new PartDetailPage({ el: ".PartDetailPage" });

}
