import Component from "vue-class-component";
import Vue from "vue";

import BeCalledBackComponent from "./be-called-back.component.vue";

@Component({
    components: {
        "be-called-back": BeCalledBackComponent
    }
})
export default class BeCalledBackMixin extends Vue {
    beCalledBackOpen = false;

    openBeCalledBack() {
        this.beCalledBackOpen = true;
    }

    closeBeCalledBack() {
        this.beCalledBackOpen = false;
    }
}
