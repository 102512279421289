import CrossSellingRequest from "./cross-selling-request";
import { CrossSellingRequestType } from "./cross-selling-request-type.enum";

export class CartCrossSellingRequest extends CrossSellingRequest {
  public type = CrossSellingRequestType.CART;
  public locale: string;
  public cartId?: string;

  constructor(data: Partial<CartCrossSellingRequest>) {
    super();
    Object.assign(this, data);
  }

}

export class AssistanceCrossSellingRequest extends CrossSellingRequest {
  public type = CrossSellingRequestType.ASSISTANCE;
  public locale: string;
  public assistanceId? : string;

  constructor(data: Partial<AssistanceCrossSellingRequest>) {
    super();
    Object.assign(this, data);
  }
}
