





















































import Vue from "vue";
import Component from "vue-class-component";
import Product from "./product";

declare var staticUrl: string;
declare var dataLayer: any;

@Component({
  props: {
    mode: {
      type: String,
      default: 'centered'
    },
    product: {
      type: Object,
      required: true
    },
    position: Number
  }
})
export default class ProductCardComponent extends Vue {
  public product: Product;
  public position: number;
  public staticUrl = staticUrl;

  public viewDetail() {
    if (this.product.dataLayer) {
            dataLayer.push({
                event: "select_item",
                ecommerce: {
                    items: [{...this.product.dataLayer, item_list_name: "Search Results"}]
                }
            });
        }
    window.location.href = this.product.link;
  }

  public viewReplacement() {
    window.location.href = this.product.replacement.link;
  }
}
