












































































import Component, {mixins} from "vue-class-component";
import {validationMixin} from "vuelidate";

declare var dataLayer:any;

@Component({
    props:{
        cart: Object,
        order: Object
    }
} as any)
export default class OrderPaymentCartComponent extends mixins(validationMixin) {

    public order:any;

}
