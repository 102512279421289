import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {},
})
export default class RedirectionAlertMixin extends Vue {

  displayRedirectAlert = true;

  public closeRedirectAlert() {
    this.displayRedirectAlert = false;
  }
}
