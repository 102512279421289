





































































































import Vue from "vue";
import Component from "vue-class-component";

declare var staticUrl: string;

@Component({})
export default class ProductAddModal extends Vue {
  public product: any = {};
  public noImageSrc = staticUrl + "/pictures/no-image.png";

  public stopPropagation($event: Event) {
    $event.stopPropagation();
  }
}
