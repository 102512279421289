import Component, { mixins } from "vue-class-component";
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import BrandtVue from "../common/brandt-vue";
import AlertComponent from "../common/ui/alert.component.vue";
import FieldComponent from "../common/ui/field.component.vue";
import SpinnerComponent from "../common/ui/spinner.component.vue";
import { constants } from "../constants";
import "./reset-password.page.scss";
import authenticationService from "../common/authentication/authentication.service";

declare var token: string;

if (document.querySelector(".ResetPasswordPage")) {
  @Component({
    components: {
      field: FieldComponent,
      alert: AlertComponent,
      spinner: SpinnerComponent
    },
    validations: {
      newPassword: {
        required,
        minLength: minLength(constants.PASSWORD_LENGTH)
      },
      newPasswordRepeat: {
        required,
        minLength: minLength(constants.PASSWORD_LENGTH),
        // tslint:disable-next-line:object-literal-shorthand
        sameAsPassword: function(value: string) {
          return value === this.newPassword;
        }
      }
    }
  } as any)
  class ResetPasswordPage extends mixins(BrandtVue, validationMixin) {
    public $t: any;
    public errorMessageKey: string = null;
    public successMessageKey: string = null;
    public isLoading = false;

    public newPassword: string = null;
    public newPasswordRepeat: string = null;

    public async resetPassword() {
      this.isLoading = true;
      this.successMessageKey = null;
      this.errorMessageKey = null;

      try {
        await authenticationService.executeResetPassword(token, this.newPassword);
        this.successMessageKey = "reset-password-execute.success-message";
      } catch (error) {
        this.errorMessageKey = "common.error-code.server";
      } finally {
        this.isLoading = false;
      }
    }

    public getErrorMessage(validation: any) {
      if (!validation.minLength) {
        return this.$t("common.error.field-min-length", [constants.PASSWORD_LENGTH]);
      }
      if (!validation.sameAsPassword) {
        return this.$t("reset-password.error.same-as-password");
      }
      return this.$t("common.error.field-required");
    }
  }

  const resetPasswordPage = new ResetPasswordPage({ el: ".ResetPasswordPage" });
}
