declare var grecaptcha: any;
declare var reCaptchaSiteKey: any;

export class ReCaptchaService {

  public async getSiteToken(action: string) {
    return await grecaptcha.execute(reCaptchaSiteKey, {action});
  }

}

export default new ReCaptchaService();
