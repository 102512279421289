import Component from "vue-class-component";
import BrandtVue from "../common/brandt-vue";

if (document.querySelector(".ArticlePage")) {

  @Component({
  })
  class ArticlePage extends BrandtVue {

  }
  const articlePage = new ArticlePage({ el: ".ArticlePage" });

}
