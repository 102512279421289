

















































import Component, { mixins } from "vue-class-component";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { AlertType } from "../../common/ui/alert-type";
import AlertComponent from "../../common/ui/alert.component.vue";
import AUTHENTICATION_CONSTANTS from "./authentication.constants";
import authenticationService from "./authentication.service";


declare var dataLayer: any;

@Component({
  template: "#common-authentication-login-form-template",
  components: {
    alert: AlertComponent,
  },
  props: {
    action: String,
  },
  validations: {
    email: {
      required,
    },
    password: {
      required,
    },
  },
} as any)
export default class LoginForm extends mixins(validationMixin) {
  public $t: any;
  public ACTION = AUTHENTICATION_CONSTANTS.ACTION;

  public action: string;
  public email: string = null;
  public password: string = null;
  public rememberMe = true;
  public submitting = false;

  public stopPropagation($event: Event) {
    $event.stopPropagation();
  }

  public changeAction(action: any) {
    this.$emit("change-action", action);
  }

  public async submit() {
    this.submitting = true;
    try {
      await authenticationService.login({ email: this.email, password: this.password });
      dataLayer.push({
        'event': 'interactionDone',
        'categoryGA': 'account',
        'actionGA': 'loginOK',
        'labelGA': 'outsideCheckout'
      });


      this.submitting = false;
      this.$emit("login");
    } catch (error) {

      dataLayer.push({
        'event': 'interactionDone',
        'categoryGA': 'account',
        'actionGA': 'loginFail',
        'labelGA': 'outsideCheckout'
      });
      this.submitting = false;
      let errorCodeKey: string;
      if (error.response && error.response.status === 401) {
        errorCodeKey = "login-form.bad-credentials-error";
      } else {
        errorCodeKey = "login-form.server-error";
      }
      this.$emit("show-alert-requested", { type: AlertType.Error, message: this.$t(errorCodeKey) });
    }
  }

}
