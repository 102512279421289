import Component from "vue-class-component";
import BrandtVue from "../common/brandt-vue";
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import './marketing.scss'
import ProductCardComponent from "../product/product-card.component.vue";
import AsideCtaInterventionComponent from "../common/aside-cta/aside-cta-intervention.component.vue"; 
import AsideCtaYoutubeComponent from "../common/aside-cta/aside-cta-youtube.component.vue"; 


if (document.querySelector(".MarketingPage")) {

  @Component({
      components: {
        "product-card": ProductCardComponent,
        "cta-intervention": AsideCtaInterventionComponent,
        "cta-youtube": AsideCtaYoutubeComponent
      }
  })
  class MarketingPage extends BrandtVue {


  }

  const marketingPage = new MarketingPage({ el: ".MarketingPage" });
}
