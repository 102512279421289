import _ from "lodash";
import Component, {mixins} from "vue-class-component";
import BrandtVue from "../common/brandt-vue";
import CrossSellingComponent from "../common/cross-selling/cross-selling.component.vue";
import FieldComponent from "../common/ui/field.component.vue";
import orderService from "../order/order.service";
import Country from "../order/step/country";
import CartDetailMixin from "./cart-detail.mixin";
import CartMixin from "./cart.mixin";
import "./cart.scss";
import moment from "moment-timezone";
import UtilsService from "../common/services/utils.service";
import {CartItem} from "./cart-item";
import {Cart} from "./cart";
import TimeslotModalComponent from "./timeslot-modal.component.vue";
import cartService from "./cart.service";
import {Timeslot} from "../common/models/timeslot.model";
import {ProductType} from "../end-product/end-product-type"
import {AssistanceType} from "../assistance/step/assistance-type.enum";
import {AssistanceSearchType} from "../assistance/step/assistance-search.type.enum";
import momentDateFormatService from "../common/momentDateFormat.service";


declare var staticUrl: string;
declare var dataLayer: any;

const MINUTE_DURATION = moment.duration(1, 'minutes').asMilliseconds();

// Display number of minutes left
function durationDisplayInMinutes(durationInMs: number){
    if( durationInMs ){
        if(durationInMs < 0){
            return '0 minutes';
        }
        if(durationInMs < MINUTE_DURATION){
            return 'moins d\'une minute'
        }

        let durationInMinute = _.round(durationInMs / MINUTE_DURATION);
        if(durationInMinute > 1){
            return durationInMinute + ' minutes'
        } else {
            return '1 minute';
        }
    }
    return 'une durée inconnue';
}

// the name to display
function computeItemTitle(item: CartItem) {
    if(item){
        let itemTitle = item.product.title;
        if( item.assistanceRdv != undefined){
            var tempTimeslot = item.assistanceRdv.timeSlot;
            return itemTitle + " - " + item.assistanceRdv.endProductReference + " - " + UtilsService.formatTimeslotDateTime(item.assistanceRdv.timeSlot);
        }
        return itemTitle;
    }
}




if (!!document.querySelector(".CartPage")) {
    @Component({
        components: { field: FieldComponent, "cross-selling": CrossSellingComponent, modal: TimeslotModalComponent },
        filters: {
            durationDisplay: durationDisplayInMinutes,
            itemTitle : computeItemTitle
        }
    })
    class CartPage extends mixins(BrandtVue, CartDetailMixin, CartMixin) {
        public countries: Country[] = [];
        public staticUrl = staticUrl;
        public noImageSrc = staticUrl + "/pictures/no-image.png";
        public cart: Cart;
        public displayCrossSellingAssistance: boolean;
        public currentTime = moment();
        public hasAtLeastOneAssistanceExpired: boolean = true;
        public hasBeenUpdatedAndHasAssistance: boolean = false;
        public corruptionAssumed: boolean = false;
        public displayModal: boolean = false;
        private assistanceRdvToUpdate: CartItem;
        private assistanceRdvType: String | null = null;
        public optitimeError: Boolean = false;


        public get timeLimitedInterventions() {
            if(!this.cart || !this.cart.items){
                return [];
            }
            return _.filter(this.cart.items, item => item.product.type === ProductType.Assistance && item.assistanceRdv != null);
        }

        private checkTime(time: any){
            return -1 * this.currentTime.diff(time);
        }

        public created() {
            cartService.checkAssistanceRdv();
            (async () => {
                await this.getCountries();
            })();
            setInterval(() => {
                if (this.timeLimitedInterventions.length === 0 && _.findIndex(this.cart.items, item => item.product.type === ProductType.Assistance) != -1){
                    this.corruptionAssumed = true;
                }
                this.currentTime = moment();

                var items = this.timeLimitedInterventions;
                var assistanceRdvs = _.map(items, item => item.assistanceRdv.timeSlotExpirationDate);
                var times = _.map(assistanceRdvs, time => this.checkTime(moment(time)));
                this.hasAtLeastOneAssistanceExpired = _.find(times, time => this.isAssistanceExpired(time)) === undefined ? false : true;
            }, 1000); // ttes les secondes
        }

        protected cartLoaded() {
            this.displayCrossSellingAssistance = this.cart.items.some(
                cartItem => cartItem.product.type === ProductType.Assistance && cartItem.product.reference === AssistanceType.TECHNICAL
            );

            this.hasBeenUpdatedAndHasAssistance = this.cart.items.some( item => item.product.type === ProductType.Assistance ) && !!this.cart.promoCode && this.cart.discountAmount === 0;

        }

        private async getCountries() {
            this.countries = await orderService.getCountries();
        }

        public handleCreateOrder() {
            const dataLayerProducts = _.map(this.cart.items, (item) => {
                return {
                    item_name: item.product.title,
                    item_id: item.product.reference,
                    price: item.product.price.value,
                    quantity: item.quantity
                };
            });

            dataLayer.push({
                event: "begin_checkout",
                ecommerce: {
                    currency: "EUR",
                    value: dataLayerProducts
                        .reduce((total: number, item: any) => total + item.price * item.quantity, 0),
                    items: dataLayerProducts,
                }
            });
        }

        public isAssistanceExpired(n: number){
            return n <= 0;
        }

        public showModal(item: CartItem){
            //necessary to block the background from scrolling when the modal is shown
            document.body.classList.add("modal-opened");
            this.assistanceRdvToUpdate = item;
            this.assistanceRdvType = this.assistanceRdvToUpdate.product.reference == AssistanceType.DIAGNOSTIC ? AssistanceSearchType.DIAGNOSTIC : AssistanceSearchType.ASSISTANCE;
            this.displayModal = true;
        }

        public cancelModal(){
            //remove the class to allow scrolling on the page
            document.body.classList.remove("modal-opened");
            this.assistanceRdvToUpdate = undefined;
            this.assistanceRdvType = null;
            this.displayModal = false;
        }

        public async confirmModal(event: Timeslot){
            //remove the class to allow scrolling on the page
            document.body.classList.remove("modal-opened");
            this.assistanceRdvToUpdate.assistanceRdv.timeSlot = this.formatTimeslotZoneDateTime(event);
            try{
                this.cart = await cartService.updateCartAssistanceRdv(this.assistanceRdvToUpdate, this.cart.id);
                this.updateCart();
                this.assistanceRdvType = null;
                this.displayModal = false;
                this.optitimeError = false;
            }
            catch (error) {

                this.optitimeError = true;
            }
        }

        private formatTimeslotZoneDateTime(timeslot: Timeslot): Timeslot{
            timeslot.startTime = momentDateFormatService.ISO8601StringToMomentToString(timeslot.startTime);
            timeslot.endTime = momentDateFormatService.ISO8601StringToMomentToString(timeslot.endTime);
            return timeslot;
        }

    }

    const cartPage = new CartPage({ el: ".CartPage" });
}
