export class LocalStorageService {

    /**
     * a stringyfied object parsed to json
     * @param key
     */
    public getItem(key: string) {
        const raw = localStorage.getItem(key);
        if (!raw) {
            return null;
        }
        return JSON.parse(raw);
    }

    /**
     * parse an object to string and store it
     * @param key 
     * @param value 
     */
    public setItem(key: string, value: any) {
        if (!key) {
            return;
        }
        localStorage.setItem(key, JSON.stringify(value));
    }

    /**
     * Removes an item
     * @param key
     */
    public removeItem(key: string) {
        if (!key) {
            return;
        }
        localStorage.removeItem(key);
    }
}

export default new LocalStorageService();