































































































import Vue from "vue";
import Component from "vue-class-component";
import {Timeslot} from "../../common/models/timeslot.model";
import UtilsService from "../../common/services/utils.service";
import SpinnerComponent from "../../common/ui/spinner.component.vue";
import AlertComponent from "../../common/ui/alert.component.vue";
import assistanceRdvService from "../assistance-rdv.service";
import cartService from "../../cart/cart.service";
import momentDateFormatService from "../../common/momentDateFormat.service";

import _ from "lodash";

@Component({
    components: {
        spinner: SpinnerComponent,
        alert: AlertComponent,
    },
    props: {
        type: String,
        reserve_error: {
            type: Boolean,
            default: false
        }
    }
})
export default class TimeslotSelectionComponent extends Vue {

    private timeslots: Timeslot[] = [];
    private timeslotsResultArray: Timeslot[] = [];
    private selectedTimeslot: Timeslot = null;

    private timeslotsChunksIndex: number = 0;
    private timeslotsChunkSize: number = 10;
    private timeslotsChunks: (Timeslot[])[] = [];
    private canDisplayMoreTimeslots: boolean = true;

    private submitting = false;
    private error: any | null = null;

    created() {
        this.loadTimeslot(this.$props.type);
    }

    async loadTimeslot(type: String) {
        if (type == undefined || type == null){
            return;
        }
        this.submitting = true;
        var request = {
            type: type,
            cartId: cartService.getCartId()
        }
        try {
            var result = await assistanceRdvService.getAssistanceTimeSlots(request);
            this.timeslotsResultArray = _.map(result, slot => {
                slot.startTime = momentDateFormatService.ISO8601StringToMomentToString(String(slot.startTime));
                slot.endTime = momentDateFormatService.ISO8601StringToMomentToString(String(slot.endTime));
                return <Timeslot>slot;
                });
            this.timeslotsChunks = _.chunk(this.timeslotsResultArray, this.timeslotsChunkSize);
            this.timeslots = this.timeslotsChunks.length > 0 ? this.timeslotsChunks[this.timeslotsChunksIndex] : [];
            this.error = null;
        }catch (e){
            this.error = e;
        }
        this.submitting = false;
    }

    loadMore() {
        if (this.timeslots.length % 10 != 0 || this.timeslots.length === 0 || this.timeslotsResultArray.length === this.timeslots.length){
            this.canDisplayMoreTimeslots = false;
            return;
        }
        this.timeslotsChunksIndex++;
        this.timeslots = _.concat(this.timeslots, this.timeslotsChunks[this.timeslotsChunksIndex])
    }

    public formatTimeslot(timeslot: Timeslot): string {
        return UtilsService.formatTimeslotDateTime(timeslot);
    }

    public cancel(){
        this.selectedTimeslot = null;
        this.$emit('cancel');
    }

    public confirm(){
        this.$emit('confirm', this.selectedTimeslot);
    }
}
