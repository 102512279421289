import Component, { mixins } from "vue-class-component";
import BrandtVue from "../common/brandt-vue";
import PhotoModalComponent from "../common/ui/photo-modal.component.vue";
import ProductCardComponent from "../product/product-card.component.vue";

import "./end-product-detail.page.scss";
import { EndProductProductSearchingMixin } from "./end-product-product-searching.mixin";
import { ProductType } from "./end-product-type";
import { Carousel, Slide } from "vue-carousel";
import VueMagnifier from "../common/ui/vue-magnifier.vue";

import AsideCtaInterventionComponent from "../common/aside-cta/aside-cta-intervention.component.vue"; 
import AsideCtaYoutubeComponent from "../common/aside-cta/aside-cta-youtube.component.vue"; 

declare var endProductReference: string;

if (document.querySelector(".EndProductDetailPage")) {

    @Component({
        components: {
            "product-card": ProductCardComponent,
            "photo-modal": PhotoModalComponent,
            "slide": Slide,
            "carousel": Carousel,
            "vue-magnifier": VueMagnifier,
            "cta-intervention": AsideCtaInterventionComponent,
            "cta-youtube": AsideCtaYoutubeComponent
        },
    })
    class EndProductDetailPage extends mixins(
        BrandtVue,
        EndProductProductSearchingMixin
    ) {
        public selectedPdfUrl: string = null;
        public ProductType = ProductType;
        public endProductReference = endProductReference;

        public created() {}
    }

    const endProductDetailPage = new EndProductDetailPage({
        el: ".EndProductDetailPage",
    });
}
