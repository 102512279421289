import Component from "vue-class-component";
import BrandtVue from "../common/brandt-vue";

if (document.querySelector(".CancelPaymentPage")) {
    
    @Component({})
    class PaymentPage extends BrandtVue {
        // not sure if needed anymore
    }
    const paymentPage = new PaymentPage({
        el: ".CancelPaymentPage"
    });
}
