










































import Component, {mixins} from "vue-class-component";
import {validationMixin} from "vuelidate";
import OrderPaymentCartComponent from "./order-payment-cart.component.vue";
import OrderPaymentChoiceComponent from "./order-payment-choice.component.vue";
import VueRouter from "vue-router";
import orderStore from "./order.store";
import FormulaireViewComponent from "./assistance-form/formulaire-view.component.vue";

@Component({
    components: {
        "order-payment-cart": OrderPaymentCartComponent,
        "order-payment-choice": OrderPaymentChoiceComponent,
        "assistance-view": FormulaireViewComponent
    },
    watch: {
        'context.termsAndConditionsAccepted': function(value: boolean) {
            this.returnToPreferencesIfNeeded();
        }
    }
})
export default class OrderPaymentComponent extends mixins(validationMixin){
    public context: any;
    public $router: VueRouter;
    public state = orderStore.state;

    canDisplayAssistanceForm = true;


    public displayAssistanceForm(){
        return this.state.order.lines.filter(line => line.assistanceRdv != null).length > 0 && this.canDisplayAssistanceForm;
    }

    getAssistanceRdvs() {
        return this.state.order.lines.filter(line => line.assistanceRdv != null);
    }

    hideForm(event: any){
        this.canDisplayAssistanceForm = false;
        window.scrollTo(0, 0);
    }
}
