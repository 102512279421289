






















import Vue from "vue";
import Component from "vue-class-component";
import { Timeslot } from "../common/models/timeslot.model";
import TimeslotSelectionComponent from "../assistance/step/timeslot-selection.component.vue";

@Component({
    components:{
        'timeslot-selection': TimeslotSelectionComponent    
    },
    props: {
        type: String,
        error: Boolean
    }
})
export default class TimeslotModalComponent extends Vue {

    public confirm(event: Timeslot) {
        var selectedTimeslot = event;
        this.$emit('confirm', selectedTimeslot);
    }

    public cancel() {
        this.$emit('close');
    }

    public stopPropagation($event: Event) {
        $event.stopPropagation();
    }

}
