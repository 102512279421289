































































































import Vue from "vue";
import Component from "vue-class-component";
import accountService from "./account.service";
import Order from "./order.model";
import moment from "moment-timezone";
import Page from "../common/models/page";
import { ROUTES } from "./account.page";
import PagerComponent from "../common/ui/pager.component.vue";
import { Route } from "vue-router";
import AlertComponent from "../common/ui/alert.component.vue";
import { AlertType } from "../common/ui/alert-type";

@Component({
  components: {
    pager: PagerComponent,
    alert: AlertComponent
  },
  props: {
    context: Object,
    account: Object
  },
  watch: {
    "$route.query.page": async function(page) {
      await this.loadOrders();
    }
  }
})
export default class OrderListComponent extends Vue {
  public $route: Route;
  public context: any;
  public orderPage: Page<Order> = null;
  public linkBase = ROUTES.ORDER_LIST;
  public AlertType = AlertType;

  public created() {
    (async () => {
      await this.loadOrders();
    })();
  }

  public async loadOrders() {
    this.context.isLoading = true;
    try {
      let page: number = parseInt(this.$route.query.page as string, 10);
      if (!Number.isInteger(page)) {
        page = 1;
      }
      this.orderPage = await accountService.getOrders(Math.max(page - 1, 0));
    } catch {}
    this.context.isLoading = false;
  }

  public formatDate(date: Date) {
    return moment(String(date)).format("DD/MM/YYYY HH:mm");
  }

  public getOrderDetailLink(order: Order) {
    return `${ROUTES.ORDERS_DETAILS}/${order.id}`;
  }
}
