



























































































































































































import Component, {mixins} from "vue-class-component";
import {validationMixin} from "vuelidate";
import {maxLength, required} from "vuelidate/lib/validators";
import FieldComponent from "../../../common/ui/field.component.vue";
import moment from "moment-timezone";
import "../../../assistance/assistance-shared.scss";

const checkPhoneNumber = (value: string) => {

    if (value.startsWith("+")){
        return value.length <= 15 && /^\+[0-9]+$/.test(value);
    } else {
        return value.length <= 10 && /^[0-9]+$/.test(value);
    }
};

const checkStartUpDate = (value: string) => {
    if(value === ""){
        return true;
    }
    if(typeof value === "undefined"){
        return false;
    }
    if (value.length != 10){
        return false;
    }
    //check if value has format JJ/MM/YYYY
    if (!/^[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]$/.test(value)){
        return false;
    }
    var day = parseInt(value.split("/")[0]);
    var month = parseInt(value.split("/")[1]);
    var year = parseInt(value.split("/")[2]);
    var date = new Date(year, month-1, day);
    //if the different element from the date created with the given value doesn't match
    //it means the value given is wrong
    return !(date.getDate() != day || date.getMonth() + 1 != month || date.getFullYear() != year) && moment(value, "DD/MM/YYYY").diff(moment.now(), 'hours') <= 0;
};

@Component({
    components: {
        field: FieldComponent,
    },
    props: {
        name: String,
        firstname: String,
        phone: String,
        email: String,
        brand: String,
        reference: String,
        setup_date: String,
        displayed_code: String,
        comment: String,
        assistanceId: String,
        serialNumber: String
    },
    data: function(){
        return {
            mutableName: this.$props.name,
            mutableFirstName: this.$props.firstname,
            mutablePhone: this.$props.phone,
            mutableEMail: this.$props.email,
            mutableBrand: this.$props.brand,
            mutableReference: this.$props.reference,
            mutableSetupDate: this.$props.setup_date,
            mutableDisplayedCode: this.$props.displayed_code,
            mutableComment: this.$props.comment,
            mutableSerialNumber: this.$props.serialNumber
        }
    },
    watch: {
        "$v.$invalid": function(value: boolean) {
            this.$emit("is-invalid", value);
        }
    },
    validations: {
        mutableName: {
            required,
            maxLength: maxLength(100)
        },
        mutableFirstName: {
            required,
            maxLength: maxLength(100)
        },
        mutablePhone: {
            required,
            checkPhoneNumber
        },
        mutableEMail: {
            required,
            maxLength: maxLength(255)
        },
        mutableBrand: {
            required,
            maxLength: maxLength(32)
        },
        mutableReference: {
            required,
            maxLength: maxLength(32)
        },
        mutableSetupDate: {
            checkStartUpDate
        },
        mutableDisplayedCode: {
            maxLength: maxLength(32)
        },
        mutableComment: {
            required
        },
        mutableSerialNumber: {
            maxLength: maxLength(100)
        }
    },
} as any)
export default class AssistanceFormulaireComponent extends mixins(validationMixin) {

    public $v: any;

    public getErrorKey(validation: any) {
        if (validation.maxLength === false) {
            return "common.error.field-max-length";
        }
        if (validation.checkPhoneNumber === false){
            return "assistance.formulaire.phoneNumber.error";
        }
        if (validation.checkStartUpDate === false){
            return "assistance.formulaire.date.error";
        }
        return "common.error.field-required";
    }

    public checkForm(){
        var resultForm = {
            "name": this.$data.mutableName,
            "firstname": this.$data.mutableFirstName,
            "phone": this.$data.mutablePhone,
            "email": this.$data.mutableEMail,
            "brand": this.$data.mutableBrand,
            "reference": this.$data.mutableReference,
            "displayedCode": this.$data.mutableDisplayedCode,
            "setupDate": this.$data.mutableSetupDate,
            "comment": this.$data.mutableComment,
            "assistanceId": this.$props.assistanceId,
            "serialNumber": this.$data.mutableSerialNumber
        }
        this.$emit("form-blur", resultForm);
        this.$emit("is-invalid", this.$v.$invalid);
    }
}
