import Vue from "vue";
import Component from "vue-class-component";
import localStorageService from "../services/local-storage.service";
import "./cookie-banner.scss";

@Component({})
export default class CookieBannerMixin extends Vue {
  public cookieBarAccepterName = "cookieBarAccepted";
  public showCookieBanner = false;

  public created() {
    let shouldShowCookieBanner;
    if (localStorageService.getItem(this.cookieBarAccepterName) === null) {
      shouldShowCookieBanner = true;
    } else {
      shouldShowCookieBanner = false;
    }
    this.showCookieBanner = shouldShowCookieBanner;
  }

  public acceptCookie() {
      localStorageService.setItem(this.cookieBarAccepterName, true);
      this.showCookieBanner = false;
  }
}
