

















































































































import Component, { mixins } from "vue-class-component";
import Order from "./order.model";
import FieldComponent from "../common/ui/field.component.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import OrderReturnRequest from "./order-return-request";
import Account from "./account";
import accountService from "./account.service";
import AlertComponent from "../common/ui/alert.component.vue";
import _ from "lodash";
import SpinnerComponent from "../common/ui/spinner.component.vue";

declare var orderReturnEmail: string;

@Component({
  props: {
    order: Object
  },
  components: {
    field: FieldComponent,
    alert: AlertComponent,
    spinner: SpinnerComponent
  },
  watch: {
    "returnForm.productId": function() {
      this.updateAvailableQuantity();
    }
  },
  validations: {
    returnForm: {
      productId: {
        required
      },
      firstName: {
        required
      },
      lastName: {
        required
      },
      fromEmail: {
        required
      },
      quantity: {
        required
      },
      motive: {
        required
      },
      comments: {}
    }
  }
} as any)
export default class OrderReturnForm extends mixins(validationMixin) {
  public $t: any;
  public order: Order;
  public returnForm: OrderReturnRequest = new OrderReturnRequest();
  public account: Account = null;
  public errorCodeKey: string = null;
  public availableQuantities: any[] = [];
  public success: boolean = null;
  public loading = false;
  public orderReturnEmail = orderReturnEmail;
  public get motives() {
    const prefixKey = "account.return-form.motives";
    const motives = [];
    for (let i = 0; i < 5; i++) {
      motives.push({
        code: this.$t(prefixKey + "." + i),
        label: this.$t(prefixKey + "." + i)
      });
    }
    return motives;
  }

  public mounted() {
    this.loading = true;
    (async () => {
      try {
        this.returnForm.productId = _.first(this.order.lines).productId;
        this.updateAvailableQuantity();
        this.account = await accountService.getAccount();
        this.returnForm.firstName = this.account.firstName || "";
        this.returnForm.lastName = this.account.lastName || "";
        this.returnForm.fromEmail = this.account.email || "";
      } catch (e) {
        this.errorCodeKey = "account.return-form.initialization-error";
      } finally {
        this.loading = false;
      }
    })();
  }

  public async submit() {
    this.loading = true;
    this.errorCodeKey = null;
    this.returnForm.orderId = this.order.id;

    try {
      await accountService.sendOrderReturnRequest(this.returnForm);
      this.success = true;
    } catch (e) {
      this.errorCodeKey = "account.return-form.send-error";
      this.success = false;
    } finally {
      this.loading = false;
    }
  }

  public productIds() {
    return _.map(this.order.lines, line => ({
      code: line.productId,
      label: line.label
    }));
  }

  public updateAvailableQuantity() {
    this.availableQuantities.splice(0, this.availableQuantities.length);

    for (let line of this.order.lines) {
      if (line.productId === this.returnForm.productId) {
        for (let i = 1; i <= line.quantity; i++) {
          this.availableQuantities.push({ code: i, label: i });
        }
      }
    }

    this.returnForm.quantity = 1;
  }
}
