


















import Vue from "vue";
import Component from "vue-class-component";
import Page from "../models/page";

@Component({
  props: {
    page: Object,
    linkBase: String,
  }
})
export default class PagerComponent extends Vue {

  public page: Page<any>;
  public boundaries = 5;
  public linkBase: string;

  public buildLink(i: number) {
    return this.linkBase + "?page=" + i;
  }

}
