









































































import Vue from "vue";
import Component from "vue-class-component";
import { AlertType } from "./alert-type";

@Component({
  template: "#common-ui-alert-template",
  props: {
    type: {
      type: String,
      default: "success"
    },
    message: {
      type: String
    }
  }
})
export default class AlertComponent extends Vue {
  public AlertType = AlertType;

  public message: string;
  public type: AlertType;
}
