




























































import Vue from "vue";
import Component from "vue-class-component";
import AssistanceFormulaireComponent from "./formulaire.form.component.vue";
import AssistanceRDVService from "../../../assistance/assistance-rdv.service";
import SpinnerComponent from "../../../common/ui/spinner.component.vue";
import AlertComponent from "../../../common/ui/alert.component.vue";
import {AssistanceType} from "../../../assistance/step/assistance-type.enum";

@Component({
    components: {
        "assistance-form": AssistanceFormulaireComponent,
        spinner: SpinnerComponent,
        alert: AlertComponent,
    },
    props: {
        assistanceRdvs: Array,
        phone: String,
        email: String,
        name: String,
        firstname: String,
        brand: String
    }
})
export default class FormulaireViewComponent extends Vue {

    public isFormInvalid: boolean = true;

    public resultForm: any;

    private submitting = false;
    private error: any = null;

    // truth source about current form
    public formDisplayCounter = 0;
    public isDiagnostic: boolean = true;

    getBrand(orderLineAssistanceRdv: any){
        return orderLineAssistanceRdv.assistanceRdv.endProductBrand;
    }

    getReference(orderLineAssistanceRdv: any){
        return orderLineAssistanceRdv.assistanceRdv.endProductReference;
    }

    getId(orderLineAssistanceRdv: any){
        return orderLineAssistanceRdv.assistanceRdv.id;
    }

    getFormPosition(index: number){
        let total = this.$props.assistanceRdvs.length;
        return ` ${index+1}/${total}`
    }

    getValidationButtonLabel(){
        return this.$props.assistanceRdvs.length - 1 === this.formDisplayCounter ?
            'assistance.formulaire.to-paiement-button' :
            'assistance.formulaire.button';
    }

    mounted(){
        this.updateTitle();
        this.resultForm = {
            "name": this.$props.name,
            "firstname": this.$props.firstname,
            "phone": this.$props.phone,
            "email": this.$props.email,
            "brand": "",
            "reference": "",
            "displayedCode": "",
            "setupDate": "",
            "comment": "",
            "serialNumber": ""
        }
    }

    updateTitle(){
        this.isDiagnostic = this.$props.assistanceRdvs[this.formDisplayCounter].product.reference === AssistanceType.DIAGNOSTIC;
    }

    updateIsFormInvalid(event: any){
        this.isFormInvalid = event;
    }

    updateForm(event: any){
        this.resultForm = event;
    }

    goToPrecedentForm(){
        // decrement counter
        this.formDisplayCounter --;
        // data that will be updated
        var currentAssistanceId = this.$props.assistanceRdvs[this.formDisplayCounter].id;
        // ugly cast because this.$refs[currentAssistanceId] gives VueElement[] and
        // gulp says that it really gives Vue | Element | Vue[] | Element[]
        (this.$refs[currentAssistanceId] as any[])[0].checkForm();
        this.updateTitle();
        window.scrollTo(0, 0);
    }

    redirect(){
        this.submitting = true;
        this.updateAssistanceRDV().then(() => {
            if (this.$props.assistanceRdvs.length - 1 === this.formDisplayCounter){
                this.$emit("hide-form");
            }else{
                this.isFormInvalid = true;
                this.formDisplayCounter ++;
                this.updateTitle();
                window.scrollTo(0, 0);
            }
        }).catch((e) => {
            this.error = e
        }).finally(() => {
            this.submitting = false;
        });
    }

    async updateAssistanceRDV() {
        var assistanceRDV = {
                assistanceRdvId: this.resultForm.assistanceId,
                firstName: this.resultForm.firstname,
                lastName: this.resultForm.name,
                phoneNumber: this.resultForm.phone,
                email: this.resultForm.email,
                firstUseDate: this.resultForm.setupDate,
                errorCodeDisplayed: this.resultForm.displayedCode,
                otherComments: this.resultForm.comment,
                serialNumber: this.resultForm.serialNumber
        }
        await AssistanceRDVService.updateAssistanceRDV(assistanceRDV);
    }


}
