


















































































import Vue from "vue";
import Component from "vue-class-component";

@Component({
  props: {
    imgSrc: {
      type: String,
      required: true,
    }
  }
})
export default class PhotoModalComponent extends Vue {

}
