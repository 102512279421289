import { render, staticRenderFns } from "./address-list.component.vue?vue&type=template&id=092890e4&"
import script from "./address-list.component.vue?vue&type=script&lang=ts&"
export * from "./address-list.component.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "address-list.component.vue"
export default component.exports