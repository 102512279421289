













































import Vue from "vue";
import Component from "vue-class-component";

declare var assistanceLink: string;
declare var interventionImagePath: string;

@Component({})
export default class AsideCtaInterventionComponent extends Vue {
    interventionImagePath: string = interventionImagePath;
    assistanceLink: string = assistanceLink;

    interventionRedirection(): void {
        window.open(assistanceLink, "_blank");
    }
}
