import Vue from "vue";
import Component from "vue-class-component";
import BrandtVue from "../common/brandt-vue";
import VueSimpleAccordion from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import './faq.scss'

if (document.querySelector(".FaqPage")) {

  Vue.use(VueSimpleAccordion);

  @Component({})
  class FaqPage extends BrandtVue {

  }

  const faqPage = new FaqPage({ el: ".FaqPage" });

}
