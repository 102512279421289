

















































import Component, {mixins} from "vue-class-component";
import PartFinderMixin from "../end-product/part-finder.mixin";
import endProductService from "../end-product/end-product.service";

@Component({
  props: {
    reference: String,
    type: String
  },
  data: function () {
    return {
      status: 'SEARCHING',
      checkedProduct: {},
      result: {}
    }
    /*return {
      status: 'CHECKED',
      checkedProduct: { reference: 'DOP740XS' },
      result: { compatible: false }
    }*/
  }
})
export default class CompatibilityChecker extends mixins(PartFinderMixin) {
  async checkCompatibility(endProduct: any) {
    this.$data.checkedProduct = endProduct;
    this.$data.status = 'CHECKING';
    this.$data.result = await endProductService.isProductCompatible(
      this.$props.reference,
      this.$props.type,
      endProduct.reference);
    this.$data.status = 'CHECKED';
  }

  reset() {
    this.$data.status = 'SEARCHING';
    this.$data.query = null;
  }
}
