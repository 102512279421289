import axios from "axios";
import Page from "../common/models/page";
import AutoCompleteReferenceReply from "./auto-complete-reference-reply";
import apiClient from "../common/services/api.client";
import {EndProductProduct} from "./end-product-product";
import {ProductType} from "./end-product-type";
import EndProduct from "./end-product";


declare var brand: string;

export class EndProductService {

  public async findProductsForPosition(reference: string, position: number, productType: ProductType): Promise<EndProductProduct[]> {
    const endProductAccessory = await apiClient.get<EndProductProduct[]>("/end-product/products?locale=" + locale + "&endProductReference=" + reference + "&position=" + position + "&brand=" + brand + "&productType=" + productType);
    return endProductAccessory;
  }

  public async findReferences(reference: string): Promise<Page<AutoCompleteReferenceReply>> {
    const queryString = "?match=" + reference + "&locale=" + locale + "&page=0&size=10";
    const searchReply = await axios.get(apiUrl + "/api/1.0.0/end-product/reference" + queryString);
    return searchReply.data as Page<AutoCompleteReferenceReply>;
  }

  public async findSlug(query: string) {
    let url = "/end-product/slug";
    //try catch is necessary because if an incorrect reference is given, an not found error is thrown by the api
    try {
      return apiClient.put<EndProduct, any>(url, {slug: query, locale: locale});
    }
    //if an error is thrown we return undefined
    catch (err){
      return undefined;
    }
  }

  public async isProductCompatible(
    productReference: string,
    productType: string,
    endProductReference: string) {
    return await apiClient.post<any, any>("/product/compatibility", {
      productReference,
      productType,
      endProductReference,
      locale
    });
  }
}

export default new EndProductService();
