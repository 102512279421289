














































































import Component from "vue-class-component";
import Vue from "vue";
import OrderPackage from "./order-package";
import TransporterLinkComponent from "./transporter-link.component.vue";
import AlertComponent from "../common/ui/alert.component.vue";
import { AlertType } from "../common/ui/alert-type";

@Component({
  props: {
    packages: Array,
    incompletePackages: Boolean
  },
  components: {
    "transporter-link": TransporterLinkComponent,
    alert: AlertComponent
  }
})
export default class OrderTrackingComponent extends Vue {
  public packages: OrderPackage[];
  public incompletePackages: boolean;
  public AlertType = AlertType;
}
