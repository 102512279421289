import Vue from "vue";
import Component from "vue-class-component";
import "./accessory-category-menu.mixin.scss";

@Component({})
export default class AccessoryCategoryMenuMixin extends Vue {
  public unfoldedCategories: string[] = [];
  public initializedCategories: string[] = [];

  public categoryUnfolded(id: string, initiallyUnfolded: boolean) {
      if (initiallyUnfolded && this.initializedCategories.indexOf(id) < 0) {
          this.unfoldedCategories.push(id);
          this.initializedCategories.push(id);
      }
      return this.unfoldedCategories.indexOf(id) >= 0;
  }

  public toggleCategory(id: string) {
      if (this.unfoldedCategories.indexOf(id) >= 0) {
          this.unfoldedCategories.splice(this.unfoldedCategories.indexOf(id), 1);
      } else {
          this.unfoldedCategories.push(id);
      }
  }
}
