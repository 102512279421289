











import Component from "vue-class-component";
import Vue from "vue";
import OrderPackage from "./order-package";
import { Transporter } from "./transporter";

@Component({
  props: {
    pack: Object
  }
})
export default class TransporterLinkComponent extends Vue {

  public pack: OrderPackage;

  public getLink() {
    let monitoringUrl = this.pack?.monitoringUrl;
    if(!monitoringUrl || !this.pack?.transporter){
      return 'https://www.laposte.fr/outils/suivre-vos-envois';
    }
    if(!this.pack.trackingNumber){
      return monitoringUrl;
    }
    switch (this.pack.transporter) {
      case Transporter.LaPosteColissimo:
        return `${monitoringUrl}?code=${this.pack.trackingNumber}`;
      case Transporter.TNT:
        return `${monitoringUrl}?bonTransport=${this.pack.trackingNumber}`;
      default:
        return monitoringUrl;
    }
  }

}
